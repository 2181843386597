import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import {
  IActivity,
  IAffiliation,
  IAward,
  ICommunityActivity,
  IJob,
  IResume,
} from "../../interfaces";
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import Alert from "reactstrap/lib/Alert";
// import regular from "../../fonts/Roboto-Regular.ttf";
// import bold from "../../fonts/Roboto-Bold.ttf";
import usePDF from "./usePdf";
import Button from "reactstrap/lib/Button";
import fileDownload from "js-file-download";

interface IResumePDF {
  resume: IResume;
}
// for some reason Source Sans Pro fails to render all characters but Robot works
// const FONT = "Roboto";
const BG_COLOR = "#f4f9fa";

// Font.register({
//   family: FONT,
//   format: "truetype",
//   fonts: [
//     {
//       src: regular,
//     },
//     {
//       src: bold,
//       fontWeight: 900,
//     },
//   ],
// });

const SECTION_MARGIN = 8;
const BASE_FONT_SIZE = 8;
const SPACING = 1.5;

const H4: React.FC = ({ children }) => (
  <Text
    style={{
      fontSize: BASE_FONT_SIZE * 2,
    }}
  >
    {children}
  </Text>
);
const H5: React.FC = ({ children }) => (
  <Text
    style={{
      fontSize: BASE_FONT_SIZE * 1.5,
    }}
  >
    {children}
  </Text>
);
const ResText: React.FC<{ style?: any }> = ({ children, style }) => (
  <Text style={{ fontSize: BASE_FONT_SIZE }}>{children}</Text>
);
const PersonalItem: React.FC<{ label: string }> = ({ label, children }) =>
  children ? (
    <View style={{ marginBottom: SPACING }}>
      <Text
        style={{
          fontWeight: 900,
          fontSize: BASE_FONT_SIZE,
          marginBottom: SPACING,
        }}
      >
        {label}:
      </Text>
      <ResText>{children}</ResText>
    </View>
  ) : null;
const MainContentItem: React.FC<{ label: string }> = ({ label, children }) =>
  children ? (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: SPACING,
      }}
    >
      <Text
        style={{
          fontSize: BASE_FONT_SIZE,
          fontWeight: "light",
          flex: 1,
          marginRight: 8,
        }}
      >
        {label}:
      </Text>
      <Text style={{ fontSize: BASE_FONT_SIZE, flex: 2 }}>{children}</Text>
    </View>
  ) : null;

const Spacer: React.FC = ({ children }) => (
  <View style={{ paddingVertical: 4 }}>{children}</View>
);
const HighSchoolSection: React.FC<{
  items: { name: string; data?: string | null | number }[];
  heading: string;
}> = ({ items, heading }) => {
  return (
    <View>
      <Spacer>
        <H5>{heading}</H5>
      </Spacer>
      {items.map((i) => (
        <MainContentItem key={i.name} label={i.name}>
          {i.data}
        </MainContentItem>
      ))}
    </View>
  );
};
const GreekAffiliations: React.FC<{ affiliations: IAffiliation[] }> = ({
  affiliations,
}) => {
  return (
    <>
      {affiliations.map((a, idx) => {
        const hasNext =
          affiliations.length > 1 && idx < affiliations.length - 1;
        return (
          <View key={a.name} style={getBorderStyle(hasNext)}>
            {[
              { name: "Full Name", data: a.name },
              { name: "Relationship", data: a.relationship },
              { name: "Sorority", data: a.sorority },
              { name: "College or University Campus", data: a.campus },
              { name: "City, State", data: `${a.city}, ${a.state}` },
              {
                name: "Dates Attended",
                data: `${a.date_began ?? "N/A"} - ${a.date_ended ?? "N/A"}`,
              },
              { name: "Contact's Email", data: a.email },
              { name: "Contact's Phone", data: a.mobile_number },
            ].map((i) => (
              <MainContentItem key={i.name} label={i.name}>
                {i.data}
              </MainContentItem>
            ))}
          </View>
        );
      })}
    </>
  );
};
const Activities: React.FC<{ activities: IActivity[] }> = ({ activities }) => {
  return (
    <>
      {activities.map((a, idx) => {
        const hasNext = activities.length > 1 && idx < activities.length - 1;
        return (
          <View key={a.id} style={getBorderStyle(hasNext)}>
            {[
              { name: "Activity/Interest Name", data: a.subject },
              { name: "Leadership Role", data: a.leadership_role },
              {
                name: "Years participated",
                data: `${a.year_began} - ${a.year_ended}`,
              },
            ].map((i) => (
              <MainContentItem key={i.name} label={i.name}>
                {i.data}
              </MainContentItem>
            ))}
          </View>
        );
      })}
    </>
  );
};
const Awards: React.FC<{ awards: IAward[] }> = ({ awards }) => {
  return (
    <>
      {awards.map((a, idx) => {
        const hasNext = awards.length > 1 && idx < awards.length - 1;
        return (
          <View key={a.id} style={getBorderStyle(hasNext)}>
            {[
              { name: "Title of Award/Honor", data: a.title },
              { name: "Organization", data: a.organization },
              {
                name: "Year Awarded",
                data: a.year,
              },
            ].map((i) => (
              <MainContentItem key={i.name} label={i.name}>
                {i.data}
              </MainContentItem>
            ))}
          </View>
        );
      })}
    </>
  );
};
const CommunityInvolvement: React.FC<{
  communityActivities: ICommunityActivity[];
}> = ({ communityActivities }) => {
  return (
    <>
      {communityActivities.map((a, idx) => {
        const hasNext =
          communityActivities.length > 1 &&
          idx < communityActivities.length - 1;
        return (
          <View key={a.id} style={getBorderStyle(hasNext)}>
            {[
              { name: "Role", data: a.role },
              { name: "Activity/Event", data: a.event },
              {
                name: "Description",
                data: a.description,
              },
            ].map((i) => (
              <MainContentItem key={i.name} label={i.name}>
                {i.data}
              </MainContentItem>
            ))}
          </View>
        );
      })}
    </>
  );
};
const Job: React.FC<{
  jobs: IJob[];
}> = ({ jobs }) => {
  return (
    <>
      {jobs.map((a, idx) => {
        const hasNext = jobs.length > 1 && idx < jobs.length - 1;
        return (
          <View key={a.id} style={getBorderStyle(hasNext)}>
            {[
              { name: "Employer", data: a.employer },
              { name: "Position", data: a.position },
              {
                name: "Dates",
                data: `${a.date_began ?? "N/A"} - ${a.date_ended ?? "N/A"}`,
              },
            ].map((i) => (
              <MainContentItem key={i.name} label={i.name}>
                {i.data}
              </MainContentItem>
            ))}
          </View>
        );
      })}
    </>
  );
};
const Section: React.FC = ({ children }) => (
  <View style={{ marginTop: SECTION_MARGIN }}>{children}</View>
);
const Personal: React.FC<IResumePDF> = ({ resume }) => {
  return (
    <>
      <View style={{ marginTop: resume.profile?.profile_photo_url ? 28 : 14 }}>
        <H4>Personal</H4>
        <Spacer>
          <View>
            <PersonalItem label="Phone">
              {resume.profile?.mobile_number}
            </PersonalItem>
            <PersonalItem label="Email">
              {resume.profile?.pnm_email}
            </PersonalItem>
            <PersonalItem label="Address">
              {resume.profile?.street_address}
            </PersonalItem>
            <PersonalItem label="Birth Date">
              {resume.profile?.date_of_birth}
            </PersonalItem>
          </View>
        </Spacer>
        <View>
          <Spacer>
            <H5>Social Media</H5>
          </Spacer>
          <PersonalItem label="Facebook">
            {resume.profile?.facebook_url || "Not provided"}
          </PersonalItem>
          <PersonalItem label="Instagram">
            {resume.profile?.instagram_name || "Not provided"}
          </PersonalItem>
          <PersonalItem label="Snapchat">
            {resume.profile?.snapchat_name || "Not provided"}
          </PersonalItem>
          <PersonalItem label="Twitter">
            {resume.profile?.twitter_name || "Not provided"}
          </PersonalItem>
        </View>
      </View>
    </>
  );
};

function getSrc(imgURL: string) {
  const selectedMethod = "GET" as const;
  return { uri: imgURL, method: selectedMethod, body: "", headers: "" };
}
export const ResumeComp: React.FC<IResumePDF> = React.memo(
  ({ resume }) => {
    const {
      affiliations,
      awards,
      activities,
      communityActivities,
      jobs,
    } = resume;
    if (!resume.profile) return null;
    const imageUrl = String(resume.profile.profile_photo_url);
    return (
      <Document>
        <Page size="A4">
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View
              style={{
                height: "100%",
                backgroundColor: BG_COLOR,
                paddingLeft: 12,
                paddingRight: 12,
                width: 150,
              }}
            >
              <Personal resume={resume} />
            </View>
            <View
              style={{
                paddingHorizontal: 12,
                flex: 2,
                width: "100%",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 8,
                }}
              >
                {Boolean(resume.profile?.profile_photo_url) && (
                  <Image
                    style={{
                      width: 80,
                      height: 80,
                      borderRadius: 70,
                      marginRight: 10,
                    }}
                    source={getSrc(imageUrl)}
                  />
                )}
                <View>
                  <Text
                    style={{ fontSize: BASE_FONT_SIZE * 2, maxWidth: "80%" }}
                    wrap
                  >
                    {resume.profile?.name}
                  </Text>
                  {resume.profile?.preferred_name && (
                    <Text
                      style={{ width: "100%", fontSize: BASE_FONT_SIZE * 1.7 }}
                    >
                      {`"${resume.profile?.preferred_name}"`}
                    </Text>
                  )}
                </View>
              </View>

              <View style={{ marginTop: SECTION_MARGIN }}>
                <H4>Education</H4>
                <View style={{ paddingVertical: 4 }}>
                  <H5>College/University Attending</H5>
                </View>

                <MainContentItem label="College/University Name">
                  {resume.profile.university}
                </MainContentItem>
                <MainContentItem label="Intended Major">
                  {resume.profile.intended_major}
                </MainContentItem>
                <MainContentItem label="Entering College As">
                  {resume.profile.entering_as}
                </MainContentItem>
                <MainContentItem label="Transferring from">
                  {resume.profile.transfer_from}
                </MainContentItem>

                <HighSchoolSection
                  heading="High School"
                  items={[
                    { name: "High School Name", data: resume.profile.hs_name },
                    {
                      name: "High School Full Address",
                      data: resume.profile.hs_address,
                    },
                    {
                      name: "Curriculum",
                      data: resume.profile.curriculum?.join(", "),
                    },
                    { name: "GPA", data: resume.profile.hs_gpa },
                    { name: "GPA Scale", data: resume.profile.hs_gpa_scale },
                    { name: "Class Size", data: resume.profile.hs_class_size },
                    {
                      name: "Class Rank",
                      data: resume.profile.hs_class_rank ?? "N/A",
                    },
                    { name: "ACT Score", data: resume.profile.act_score },
                    { name: "SAT Score", data: resume.profile.sat_score },
                  ]}
                ></HighSchoolSection>

                <Section>
                  <H4>Family</H4>
                  <Spacer>
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: BASE_FONT_SIZE * 1.5,
                      }}
                    >
                      Mother
                    </Text>
                  </Spacer>
                  {[
                    { name: "Full Name", data: resume.profile.mother_name },
                    {
                      name: "Maiden Name",
                      data: resume.profile.mother_maiden_name,
                    },
                    {
                      name: "Profession",
                      data: resume.profile.mother_profession,
                    },
                    {
                      name: "Did she attend a College or University?",
                      data: resume.profile.mother_college
                        ? `Yes, ${resume.profile.mother_college}`
                        : "No",
                    },
                    {
                      name: "Did she obtain a degree?",
                      data: resume.profile.mother_degree
                        ? `Yes, ${resume.profile.mother_degree}`
                        : "No",
                    },
                  ].map((i) => (
                    <MainContentItem key={i.name} label={i.name}>
                      {i.data}
                    </MainContentItem>
                  ))}

                  <Spacer>
                    <Text
                      style={{
                        fontWeight: "extrabold",
                        fontSize: BASE_FONT_SIZE * 1.5,
                      }}
                    >
                      Father
                    </Text>
                  </Spacer>
                  {[
                    { name: "Full Name", data: resume.profile.father_name },
                    {
                      name: "Profession",
                      data: resume.profile.father_profession,
                    },
                    {
                      name: "Did he attend a College or University?",
                      data: resume.profile.father_college
                        ? `Yes, ${resume.profile.father_college}`
                        : "No",
                    },
                    {
                      name: "Did he obtain a degree?",
                      data: resume.profile.father_college
                        ? `Yes, ${resume.profile.father_college}`
                        : "No",
                    },
                  ].map((i) => (
                    <MainContentItem key={i.name} label={i.name}>
                      {i.data}
                    </MainContentItem>
                  ))}
                </Section>
                {!resume.profile.opt_out_greek_affiliations &&
                  affiliations &&
                  affiliations.length > 0 && (
                    <Section>
                      <Spacer>
                        <H4>Greek Affiliations</H4>
                      </Spacer>
                      <GreekAffiliations
                        affiliations={affiliations}
                      ></GreekAffiliations>
                    </Section>
                  )}
                {!resume.profile.opt_out_activities &&
                  activities &&
                  activities.length > 0 && (
                    <Section>
                      <Spacer>
                        <H4>Activities &amp; Interests</H4>
                      </Spacer>
                      <Activities activities={activities} />
                    </Section>
                  )}
                {!resume.profile.opt_out_awards && awards && awards.length > 0 && (
                  <Section>
                    <Spacer>
                      <H4>Awards &amp; Honors</H4>
                    </Spacer>
                    <Awards awards={awards} />
                  </Section>
                )}
                {!resume.profile.opt_out_community_activities &&
                  communityActivities &&
                  communityActivities.length > 0 && (
                    <Section>
                      <Spacer>
                        <H4>Community Involvement</H4>
                      </Spacer>
                      <CommunityInvolvement
                        communityActivities={communityActivities}
                      />
                    </Section>
                  )}
                {!resume.profile.opt_out_jobs && jobs && jobs.length > 0 && (
                  <Section>
                    <Spacer>
                      <H4>Employment</H4>
                    </Spacer>
                    <Job jobs={jobs} />
                  </Section>
                )}
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.resume.profile?.name !== nextProps.resume.profile?.name;
  }
);
const useHack = (exists: boolean) => {
  const [open, setIsOpen] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      if (!open && exists) {
        setIsOpen(true);
      }
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exists]);
  return open;
};
const ErrorFallback: React.FC<{ error: any }> = ({ error }) => {
  return (
    <Alert role="alert" color="danger">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <p>Please refresh your browser and try again</p>
    </Alert>
  );
};
export const ResumePDFDownloadLink: React.FC<IResumePDF> = ({ resume }) => {
  const { blob } = usePDF(resume);
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Button
        className="btn-slim btn btn-secondary"
        onClick={() => {
          if (blob) {
            fileDownload(blob, "RecHub_Resume.pdf");
          }
        }}
      >
        <i className="fas fa-download mr-2" /> Download
      </Button>
    </ErrorBoundary>
  );
};

export const ResumePDFViewer: React.FC<IResumePDF> = ({ resume }) => {
  const exists = Boolean(resume.profile);
  const open = useHack(exists);

  if (!open) return null;
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <PDFViewer style={{ width: "100%", height: 800 }}>
        <ResumeComp resume={resume} />
      </PDFViewer>
    </ErrorBoundary>
  );
};

function getBorderStyle(hasNext: boolean) {
  return {
    marginBottom: hasNext ? 4 : 0,
  };
}
