import React, { useEffect, useState } from "react";
import { pdf } from "@react-pdf/renderer";
import { ResumeComp } from "./ResumePDF";
import { IResume } from "../../interfaces";

interface OnPDF {
  loading: boolean;
  documentURL?: string;
  blob?: Blob;
}

function usePDF(resume: IResume): OnPDF {
  const [loading, setLoading] = useState(true);
  const [documentURL, setDocumentURL] = useState<string | undefined>();
  const [blobState, setBlob] = useState<Blob | undefined>();

  useEffect(() => {
    const generateBlob = async () => {
      let blob;
      let retryAttempts = 0;

      setLoading(true);

      do {
        // To circumvent race condition in @react-pdf when rendering
        // multiple PDF's with custom fonts simultaneously.
        try {
          blob = await pdf(<ResumeComp resume={resume} />).toBlob();
        } catch (error) {
          // Render failed, let's try again but first some rest.
          await new Promise((resolve) => setTimeout(resolve, 500));
          retryAttempts++;
        }
      } while (!blob && retryAttempts < 3);

      try {
        if (blob) {
          const url = window.URL.createObjectURL(blob);
          setDocumentURL(url);
        }
      } catch (error) {
        console.log(`Unable to create objectURL: ${error}`);
      }
      setLoading(false);
      setBlob(blob);
    };

    if (resume) {
      generateBlob();
    } else {
      setLoading(false);
      setDocumentURL(undefined);
    }
  }, [resume]);
  return { loading, documentURL, blob: blobState };
}

export default usePDF;
