import React, { useState } from "react";
import { Button, CustomInput } from "reactstrap";

import agent from "../agent";
import {
  IRecInvite,
  ISorority,
  IApiResult,
  IProfile,
  IDashboardStats,
} from "../interfaces";

import InviteFormModal from "./InviteFormModal";
import InviteBadge from "./InviteBadge";

interface IInviteTableRowProps {
  invite: IRecInvite;
  inviteInstructions?: string;
  sorority: ISorority;
  onSend(i: IRecInvite): Promise<IApiResult>;
  onDelete(id: number): void;
  userUniversityName: string;
  profile: IProfile;
  stats: IDashboardStats;
}

const InviteTableRow: React.FC<IInviteTableRowProps> = props => {
  const [invite, setInvite] = useState(props.invite);

  // TODO: consolidate duplicated logic between InviteTableRow and InviteListItem
  function toggleEnabled() {
    const initialIsEnabled = invite.enabled ? true : false;
    setInvite({
      ...invite,
      enabled: !invite.enabled,
    });
    if (invite.id) {
      agent.Invitations.update({
        ...invite,
        enabled: !invite.enabled,
      }).then(res => {
        if (res.data) {
          setInvite(res.data);
        } else {
          setInvite({
            ...invite,
            enabled: initialIsEnabled,
          });
        }
      });
    } else {
      const newEnabledInvitation = {
        ...invite,
        enabled: true,
      };
      agent.Invitations.create(newEnabledInvitation).then(res => {
        if (res.data) {
          setInvite(res.data);
        } else {
          setInvite({
            ...invite,
            enabled: initialIsEnabled,
          });
        }
      });
    }
  }

  const [invitationModalIsOpen, setInvitationModalIsOpen] = useState(false);
  function openInvitationModal() {
    if (!invite.enabled)
    {
      toggleEnabled();
    }
    setInvitationModalIsOpen(true);
  }
  function closeInvitationModal() {
    setInvitationModalIsOpen(false);
  }

  function sendInvite(i: IRecInvite) {
    saveInvite(i);
    return props.onSend(i).then(res => {
      if (res.data) {
        setInvite(res.data);
      }
      return res;
    });
  }

  function saveInvite(i: IRecInvite): Promise<IApiResult> {
    return agent.Invitations.update(i)
      .then(res => {
        setInvite(res.data);
        return {
          message: "Invite saved",
          data: res.data,
        };
      })
      .catch(err => {
        return {
          error: true,
          message: "Failed to save invite",
        };
      });
  }

  const { sorority } = props;
  return (
    <>
      <tr className={invite.enabled ? "" : "text-light"}>
        <th scope="row">
          <CustomInput
            type="switch"
            className="pt-1"
            id={`${sorority.chapter_id}_${invite.id || "new"}-md`}
            onChange={toggleEnabled}
            checked={invite.enabled}
            label={
              invite.id
                ? sorority.sorority_name
                : `${sorority.sorority_name} (new)`
            }
            disabled={!!invite.sent_at}
          />
        </th>
        <td className="pt-3">
          <button
            className="p-0 border-0 bg-transparent btn-link"
            disabled={!invite.enabled}
            onClick={openInvitationModal}
          >
            <strong>{invite.writer_name || "Name"}</strong>
          </button>
        </td>
        <td className="pt-3">
          <button
            className="p-0 border-0 btn-link bg-transparent"
            disabled={!invite.enabled}
            onClick={openInvitationModal}
          >
            <strong>{invite.writer_email || "Email Address"}</strong>
          </button>
        </td>
        <td className="pt-3">
          <InviteBadge invite={invite} />
        </td>
        <td>
          {!invite.enabled && !invite.sent_at && (
            <Button onClick={openInvitationModal} color="primary" outline size="sm">
              New
            </Button>
          )}
          {invite.enabled && !invite.sent_at && (
            <Button
              onClick={openInvitationModal}
              color="primary"
              outline
              size="sm"
            >
              Edit
            </Button>
          )}
          {invite.enabled && !invite.sent_at && (
            <Button
              onClick={() => {
                if (invite.id) {
                  props.onDelete(invite.id);
                }
              }}
              color="link"
              size="sm"
              className="px-2"
              disabled={!invite.id || !!invite.sent_at}
            >
              <i className="fas fa-trash" />
            </Button>
          )}
        </td>
      </tr>
      {invitationModalIsOpen && (
        <InviteFormModal
          isOpen={invitationModalIsOpen}
          onClose={closeInvitationModal}
          onSend={sendInvite}
          onSaveForLater={saveInvite}
          invite={invite}
          inviteInstructions={props.inviteInstructions}
          sorority={sorority}
          userUniversityName={props.userUniversityName}
          profile={props.profile}
          stats={props.stats}
        />
      )}
    </>
  );
};

export default InviteTableRow;
