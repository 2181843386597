import React from "react";
import CircularProgressbar, {
  Props as CircularProgressbarProps,
} from "react-circular-progressbar";

interface Props extends CircularProgressbarProps {
  alerts?: number;
}

const CircularProgress: React.FC<Props> = (props) => {
  return (
    <div className="position-relative w-100 h-100">
      {props.alerts && (
        <span className="position-abolute rounded-circle text-light font-weight-bold bg-primary mb-0 rh-alert-icon">
          {props.alerts}
        </span>
      )}
      <div className="position-absolute">
        <CircularProgressbar {...props} />
      </div>
      <div className="position-absolute d-flex flex-column justify-content-center align-items-center h-100 w-100">
        {props.children}
      </div>
    </div>
  );
};

export default CircularProgress;
