import React, { useState } from "react";
import { Button, CustomInput, ListGroupItem } from "reactstrap";

import agent from "../agent";
import {
  IRecInvite,
  ISorority,
  IApiResult,
  IProfile,
  IDashboardStats,
} from "../interfaces";

import InviteFormModal from "./InviteFormModal";
import InviteBadge from "./InviteBadge";

interface IInviteListItemProps {
  invite: IRecInvite;
  inviteInstructions?: string;
  sorority: ISorority;
  onSend(i: IRecInvite): Promise<IApiResult>;
  onDelete(id: number): void;
  userUniversityName: string;
  profile: IProfile;
  stats: IDashboardStats;
}

const InviteListItem: React.FC<IInviteListItemProps> = props => {
  const [invite, setInvite] = useState(props.invite);

  // TODO: consolidate duplicated logic between InviteListItem and InviteTableRow
  function toggleInvitationEnabled() {
    const initialIsEnabled = invite.enabled ? true : false;
    setInvite({
      ...invite,
      enabled: !invite.enabled,
    });
    if (invite.id) {
      agent.Invitations.update({
        ...invite,
        enabled: !invite.enabled,
      }).then(res => {
        if (res.data) {
          setInvite(res.data);
        } else {
          setInvite({
            ...invite,
            enabled: initialIsEnabled,
          });
        }
      });
    } else {
      const newEnabledInvitation = {
        ...invite,
        enabled: true,
      };
      agent.Invitations.create(newEnabledInvitation).then(res => {
        if (res.data) {
          setInvite(res.data);
        } else {
          setInvite({
            ...invite,
            enabled: initialIsEnabled,
          });
        }
      });
    }
  }

  const [invitationModalIsOpen, setInvitationModalIsOpen] = useState(false);
  function openInvitationModal() {
    if (!invite.enabled) {
      toggleInvitationEnabled();
    }
    setInvitationModalIsOpen(true);
  }
  function closeInvitationModal() {
    setInvitationModalIsOpen(false);
  }

  function sendInvite(i: IRecInvite) {
    saveInvite(i);

    return props.onSend(i).then(res => {
      if (res.data) {
        setInvite(res.data);
      }
      return res;
    });
  }

  function saveInvite(i: IRecInvite): Promise<IApiResult> {
    return agent.Invitations.update(i)
      .then(res => {
        setInvite(res.data);
        return {
          message: "Invite saved",
          data: res.data,
        };
      })
      .catch(err => {
        return {
          error: true,
          message: "Failed to save invite",
        };
      });
  }

  const { sorority } = props;
  return (
    <>
      <ListGroupItem key={invite.id} color={invite.enabled ? "" : "light"}>
        <CustomInput
          type="switch"
          id={`${sorority.chapter_id}_${invite.id || "new"}`}
          onChange={toggleInvitationEnabled}
          checked={invite.enabled}
          label={
            invite.id
              ? sorority.sorority_name
              : `${sorority.sorority_name} (new)`
          }
        />
        <div>
          <button
            className="p-0 border-0 bg-transparent btn-link"
            disabled={!invite.enabled}
            onClick={openInvitationModal}
          >
            <strong>{invite.writer_name || "Name"}</strong>
          </button>
        </div>
        <div>
          <button
            className="p-0 border-0 bg-transparent btn-link"
            disabled={!invite.enabled}
            onClick={openInvitationModal}
          >
            <strong>{invite.writer_email || "Email Address"}</strong>
          </button>
        </div>
        <div className="mb-3">
          <InviteBadge invite={invite} />
        </div>
        <div>
          {!invite.enabled && !invite.sent_at && (
            <Button
              onClick={openInvitationModal}
              color="primary"
              outline
              size="sm"
            >
              New
            </Button>
          )}
          {invite.enabled && !invite.sent_at && (
            <Button
              onClick={openInvitationModal}
              color="primary"
              outline
              size="sm"
            >
              Edit
            </Button>
          )}
          {invite.enabled && !invite.sent_at && (
            <Button
              onClick={() => {
                if (invite.id) {
                  props.onDelete(invite.id);
                }
              }}
              color="link"
              size="sm"
              className="px-2"
              disabled={!invite.id || !!invite.sent_at}
            >
              <i className="fas fa-trash" />
            </Button>
          )}
        </div>
      </ListGroupItem>
      {invitationModalIsOpen && (
        <InviteFormModal
          isOpen={invitationModalIsOpen}
          onClose={closeInvitationModal}
          onSend={sendInvite}
          onSaveForLater={saveInvite}
          invite={invite}
          inviteInstructions={props.inviteInstructions}
          sorority={sorority}
          userUniversityName={props.userUniversityName}
          profile={props.profile}
          stats={props.stats}
        />
      )}
    </>
  );
};

export default InviteListItem;
