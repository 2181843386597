import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps, navigate } from "@reach/router";
import { Card, CardBody, FormGroup, Input, Label, Button } from "reactstrap";

import * as routes from "../../routes";

import { IProfile, IActivity, IExtraCurriculars, IOrder } from "../../interfaces";
import ActivityForm from "./ActivityForm";
import agent from "../../agent";

interface IEditActivitiesProps extends RouteComponentProps {
  profile: IProfile;
  onUpdateProfile(profile: IProfile): void;
  onSubmit(profile: IProfile): Promise<void>;
  formIsDisabled: boolean;
}

const emptyActivity: IActivity = {
  key: new Date().getMilliseconds(),
  subject: "",
  leadership_role: "",
  year_began: "",
  year_ended: "",
};

const EditActivities: React.FC<IEditActivitiesProps> = props => {
  const { profile, formIsDisabled } = props;

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0);
    }
  }, []);

  const [optOut, setOptOut] = useState(profile.opt_out_activities);
  function toggleOptOut() {
    if (optOut) {
      setOptOut(false);
      addBlankActivity();
    } else {
      setOptOut(true);
      setActivities(null);
    }
  }

  function saveAndContinue() {
    agent.Profile.update({
      ...profile,
      opt_out_activities: optOut,
    }).then(res => {
      props.onUpdateProfile(res.data);
      navigate(routes.toResumeEditAwards);
    });
  }

  const [activities, setActivities] = useState<IActivity[] | null>(null);
  useEffect(() => {
    fetchActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchActivities() {
    agent.Activities.getAll().then(res => {
      if (res.data && res.data.length > 0) {
        res.data.forEach((e, index) => {
          e.key = index;
        });
        res.data.forEach((e, index) => {
          e.order = index;
        });
        setActivities(res.data);
      } else if (
        res.data &&
        res.data.length === 0 &&
        !profile.opt_out_activities
      ) {
        addBlankActivity();
      }
    });
  }

  function addBlankActivity() {
    if (activities) {
      setActivities([
        ...activities,
        {
          ...emptyActivity,
          key: activities.length,
          order: activities.length
        },
      ]);
    } else {
      setActivities([emptyActivity]);
    }
  }

  function deleteActivity(activityToDelete: IActivity) {
    if (activityToDelete.id) {
      agent.Activities.delete(activityToDelete.id).then(res => {
        if (activities) {
          setActivities(activities.filter(a => a.id !== activityToDelete.id));
        }
      });
    } else if (activityToDelete.key) {
      if (activities) {
        setActivities(activities.filter(a => a.key !== activityToDelete.key));
      }
    }
  }

  function saveActivity(updatedActivity: IActivity) {
    if (updatedActivity.id) {
      return agent.Activities.update(updatedActivity.id, updatedActivity);
    } else {
      return agent.Activities.create(updatedActivity);
    }
  }

  function saveOrdering(activities: IActivity[]) {
      let extraCurriculars = {} as IExtraCurriculars;
      let newActivities = [] as IOrder[];

      activities.forEach((a) => {
        let newActivity = {
          id: a.id,
          order: a.order
        } as IOrder;
        newActivities.push(newActivity);
      });

      extraCurriculars.activities = newActivities;
      agent.ExtraCurriculars.order(extraCurriculars);
  }

  function reorderActivity(updatedActivity: IActivity, direction: "up" | "down") {
    if (direction === "up" && updatedActivity.order! > 0) {
      const updatedOrder = updatedActivity.order! - 1;
      let aboveItem = activities?.find(a => a.order === updatedOrder)!;
      
      aboveItem.order = updatedOrder + 1;
      updatedActivity.order = updatedOrder;
    }

    if (direction === "down" && updatedActivity.order! < (activities?.length! - 1)) {
      const updatedOrder = updatedActivity.order! + 1;
      let belowItem = activities?.find(a => a.order === updatedOrder)!;
      belowItem.order = updatedOrder - 1;
      updatedActivity.order = updatedOrder;
    }

    const sortedActivities = [...activities!].sort((a,b) => (a.order! > b.order!) ? 1 : -1)!;
    setActivities(sortedActivities);

    return saveOrdering(sortedActivities);
  }

  return (
    <>
      <Card className="rh-bg-teal border-0 mb-2">
        <CardBody className="pb-1">
          <p className="mb-2">Please list any activities and interests.</p>
          <FormGroup check className="mr-2">
            <Label check>
              <Input
                type="checkbox"
                name="optOut"
                checked={optOut === true}
                onChange={toggleOptOut}
                disabled={formIsDisabled}
              />{" "}
              I have no Activities &amp; Interests
            </Label>
          </FormGroup>
        </CardBody>
        {!optOut && activities && (
          <CardBody>
            {activities.map((a, index) => (
              <ActivityForm
                key={a.key}
                activity={a}
                onSubmit={saveActivity}
                onDelete={deleteActivity}
                formIsDisabled={formIsDisabled}
                onReorder={reorderActivity}
                upEnabled={a.order! > 0}
                downEnabled={a.order! < (activities.length - 1)}
              />
            ))}
            <Button onClick={addBlankActivity} disabled={formIsDisabled}>
              <i className="fas fa-plus mr-2" /> Add Another
            </Button>
          </CardBody>
        )}
        <CardBody className="d-flex justify-content-between">
          <Button
            tag={Link}
            to={routes.toResumeEditGreekAffiliations}
            color="link"
            className="btn-slim"
          >
            <i className="fas fa-chevron-left mr-2" /> Back
          </Button>
          <Button onClick={saveAndContinue} color="primary">
            Continue
          </Button>
        </CardBody>
      </Card>
      <Link to={routes.toResumeEditAwards}>Skip and come back to later</Link>
    </>
  );
};

export default EditActivities;
