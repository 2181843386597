import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

export const BUGSNAG_API_KEY = "b8b38ed8fe41114f9ee7f57d5997ba81";

const InitBugsnag = () => {
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
})};

export default InitBugsnag;