import React from "react";
import * as yup from "yup";
import { Formik, FormikProps, FormikActions } from "formik";
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Button,
} from "reactstrap";

import { IActivity } from "../../interfaces";
import { pastYearOptions } from "../../utils/formHelpers";
import { AxiosPromise } from "axios";

const formSchema = yup.object().shape({
  subject: yup
    .string()
    .label("Subject")
    .required(),
  leadership_role: yup
    .string()
    .label("Leadership Role")
    .required(),
  year_began: yup
    .date()
    .label("Start Year")
    .required(),
  year_ended: yup
    .date()
    .label("End Year")
    .min(yup.ref("year_began"), "End year must be after start year")
    .required(),
});

interface IActivityFormProps {
  activity: IActivity;
  formIsDisabled: boolean;
  upEnabled: boolean;
  downEnabled: boolean;
  onSubmit(activity: IActivity): AxiosPromise<IActivity>;
  onDelete(activity: IActivity): void;
  onReorder(activity: IActivity, direction: "up" | "down"): void;
}

const ActivityForm: React.FC<IActivityFormProps> = props => {
  const { activity, formIsDisabled } = props;

  function handleSubmit(values: IActivity, actions: FormikActions<IActivity>) {
    props
      .onSubmit({
        ...values,
      })
      .then(() => {
        actions.setSubmitting(false);
        actions.resetForm(values);
      });
  }

  return (
    <Formik
      initialValues={{
        ...activity,
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<IActivity>) => (
        <Row>
        <Col md="1" xs="1" className="mt-5">
          <FormGroup>
            <span>
              <i className={!formIsDisabled && props.upEnabled ? "fas fa-arrow-up fa-md arrow-button" : "fas fa-arrow-up fa-md disabled-arrow-button"}
              onClick={!formIsDisabled && props.upEnabled ? () => props.onReorder(activity, "up") : undefined}
              />
            </span>
          </FormGroup>
          <FormGroup>
            <span>
              <i className={!formIsDisabled && props.downEnabled ? "fas fa-arrow-down fa-md arrow-button" : "fas fa-arrow-down fa-md disabled-arrow-button"}
              onClick={!formIsDisabled && props.downEnabled ? () => props.onReorder(activity, "down") : undefined}
              />
            </span>
          </FormGroup>
        </Col>
        <Col md="11" xs="10">
        <Form onSubmit={formikBag.handleSubmit}>
          <FormGroup>
            <Label>
              Activity/Interest Name <span className="text-danger">*</span>
            </Label>
            <Input
              name="subject"
              value={formikBag.values.subject || ""}
              onChange={formikBag.handleChange}
              invalid={
                !!(formikBag.errors.subject && formikBag.touched.subject)
              }
              disabled={formIsDisabled}
            />
            <FormFeedback>{formikBag.errors.subject}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>
              Leadership Role <span className="text-danger">*</span>
            </Label>
            <Input
              name="leadership_role"
              value={formikBag.values.leadership_role || ""}
              onChange={formikBag.handleChange}
              invalid={
                !!(
                  formikBag.errors.leadership_role &&
                  formikBag.touched.leadership_role
                )
              }
              disabled={formIsDisabled}
            />
            <FormFeedback>{formikBag.errors.leadership_role}</FormFeedback>
          </FormGroup>
          <p className="mb-0">Years Participated:</p>
          <Row>
            <Col sm="6">
              <FormGroup>
                <Label>
                  Start Year <span className="text-danger">*</span>
                </Label>
                <Input
                  name="year_began"
                  type="select"
                  value={formikBag.values.year_began || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.year_began &&
                      formikBag.touched.year_began
                    )
                  }
                  disabled={formIsDisabled}
                >
                  <option value="" />
                  {pastYearOptions(20).map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Input>
                <FormFeedback>{formikBag.errors.year_began}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label>
                  End Year <span className="text-danger">*</span>
                </Label>
                <Input
                  name="year_ended"
                  type="select"
                  value={formikBag.values.year_ended || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.year_ended &&
                      formikBag.touched.year_ended
                    )
                  }
                  disabled={formIsDisabled}
                >
                  <option value="" />
                  {pastYearOptions(20).map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Input>
                <FormFeedback>{formikBag.errors.year_ended}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <div className="d-flex justify-content-between">
            <Button
              className="mr-2"
              onClick={() => props.onDelete(activity)}
              disabled={formIsDisabled}
            >
              Delete
            </Button>
            <Button
              color="primary"
              type="submit"
              className={formikBag.dirty ? "d-block" : "d-none"}
              disabled={formIsDisabled}
            >
              Save
            </Button>
          </div>
          <hr />
        </Form>
        
        </Col>
        </Row>
      )}
    />
  );
};

export default ActivityForm;
