import React, { useState, useContext } from "react";
import UserSettingsContext from "../context/user-settings-context";

import { Link, WindowLocation } from "@reach/router";

import {
  Button,
  Navbar as ReactstrapNavbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Collapse,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Nav,
  UncontrolledTooltip,
} from "reactstrap";

import LinkRouteAware from "../components/LinkRouteAware";

import { IUser } from "../interfaces";

import * as routes from "../routes";

import recHubLogo from "../images/rechub-logo.svg";

interface INavbarProps {
  className?: string;
  user: IUser;
  location: Location;
}

const Navbar: React.FC<INavbarProps> = props => {
  const { user } = useContext(UserSettingsContext);

  const { className = "" } = props;

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  function toggleMenu() {
    setMenuIsOpen(!menuIsOpen);
  }

  return (
    <ReactstrapNavbar
      light
      fixed="top"
      expand="md"
      className={`d-print-none bg-white border-bottom p-md-none ${className}`}
    >
      <Container>
        {user && user.id ? (
          <NavbarBrand tag={Link} to={routes.rootPath} className="mr-4">
            <img src={recHubLogo} alt="RecHub logo" width="115" />
          </NavbarBrand>
        ) : (
          <NavbarBrand
            tag="a"
            href="https://www.myrechub.com/"
            className="mr-4"
          >
            <img src={recHubLogo} alt="RecHub logo" width="115" />
          </NavbarBrand>
        )}
        <NavbarToggler onClick={toggleMenu} />
        <Collapse isOpen={menuIsOpen} navbar>
          {/* ===== BELOW MD ===== */}
          <Nav className="d-block d-md-none" navbar>
            {user && user.id ? (
              <Nav navbar className="d-block d-md-flex p-0">
                {user.paid && (
                  <>
                    <NavItem>
                      <NavLink tag={LinkRouteAware} to={routes.toDashboard}>
                        Dashboard
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={LinkRouteAware}
                        to={routes.toResumeEditPersonal}
                      >
                        Resumé
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={LinkRouteAware} to={routes.toFiles}>
                        Files
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={LinkRouteAware}
                        to={routes.toRecommendations}
                      >
                        Recommendations
                      </NavLink>
                    </NavItem>
                    {/*
                    Hidden until contacts and notifications functionality built out
                    <NavItem>
                      <NavLink tag={LinkRouteAware} to={routes.toSettings}>
                        Settings
                      </NavLink>
                    </NavItem>
                    */}
                  </>
                )}
                <NavItem>
                  <NavLink tag={LinkRouteAware} to={routes.toAccount}>
                    Account
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={LinkRouteAware} to={routes.toSignOut}>
                    Sign Out
                  </NavLink>
                </NavItem>
              </Nav>
            ) : (
              <>
                <NavItem>
                  <NavLink tag={LinkRouteAware} to={routes.toSignIn + props.location?.search}>
                    Sign In
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={LinkRouteAware} to={routes.toCreateAccount}>
                    Sign Up
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>

          {/* ===== MD AND ABOVE ===== */}
          <Nav className="d-none d-md-flex flex-grow-1" navbar>
            {user && user.id ? (
              <Nav navbar className="p-0 flex-grow-1">
                {user.paid && (
                  <>
                    <NavItem>
                      <NavLink
                        tag={LinkRouteAware}
                        to={routes.toDashboard}
                        className="rh-nav-item py-3 mr-2"
                      >
                        Dashboard
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={LinkRouteAware}
                        to={routes.toResumeEditPersonal}
                        className="rh-nav-item py-3 mr-2"
                      >
                        Resumé
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={LinkRouteAware}
                        to={routes.toFiles}
                        className="rh-nav-item py-3 mr-2"
                      >
                        Files
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={LinkRouteAware}
                        to={routes.toRecommendations}
                        className="rh-nav-item py-3 mr-2"
                      >
                        Recommendations
                      </NavLink>
                    </NavItem>
                  </>
                )}
                <UncontrolledDropdown nav inNavbar className="ml-auto mt-2">
                  <DropdownToggle
                    nav
                    className="rh-nav-btn-circle text-primary border border-primary rounded-circle text-center p-0 mr-3"
                  >
                    <span className="h3 font-weight-bold text-uppercase d-inline-block pt-1">
                      {user.email[0]}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu right>
                      <DropdownItem tag={LinkRouteAware} to={routes.toAccount}>
                        Account
                      </DropdownItem>
                    {/* 
                    Hidden until contacts and notifications functionality built out
                    <DropdownItem tag={LinkRouteAware} to={routes.toSettings}>
                      Settings
                    </DropdownItem>
                    */}
                    <DropdownItem tag={LinkRouteAware} to={routes.toSignOut}>
                      Sign Out
                    </DropdownItem>
                    <DropdownItem divider />
                    <div className="d-flex justify-content-between px-4">
                      <Link to={routes.toPrivacyPath}>
                        <small>Privacy, Terms & Cookies</small>
                      </Link>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavLink
                  style={{ cursor: "pointer" }}
                  href="mailto:support@myrechub.com"
                  id="supportContact"
                  className="rh-nav-btn-circle mt-2 text-primary border border-light rounded-circle text-center p-0"
                >
                  <span className="h3 d-inline-block pt-1">?</span>
                  <UncontrolledTooltip target="supportContact">
                    Contact Support
                  </UncontrolledTooltip>
                </NavLink>
              </Nav>
            ) : (
              <>
                <NavItem className="ml-auto">
                  <Button
                    tag={Link}
                    to={routes.toSignIn + props.location?.search}
                    color="secondary"
                    className="mr-2"
                  >
                    Sign In
                  </Button>
                </NavItem>
                <NavItem>
                  <Button
                    tag={Link}
                    to={routes.toCreateAccount}
                    color="primary"
                  >
                    Sign Up
                  </Button>
                </NavItem>
              </>
            )}
          </Nav>
        </Collapse>
      </Container>
    </ReactstrapNavbar>
  );
};

export default Navbar;
