import React, { useState, useEffect } from "react";
import { RouteComponentProps, Link, navigate } from "@reach/router";
import { Formik, FormikActions, FormikProps } from "formik";
import * as yup from "yup";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import agent, { API_BASE_URL } from "../agent";
import * as routes from "../routes";

import iconLogoC from "../images/icon-logo-c.svg";

import Layout from "../components/Layout";

import PasswordPeekInput from "../components/PasswordPeekInput";

const formSchema = yup.object().shape({
  email: yup
    .string()
    .label("Email")
    .email()
    .required(),
  password: yup
    .string()
    .label("Password")
    .required(),
});

interface ISignInFormValues {
  email: string;
  password: string;
}

interface ISignInProps extends RouteComponentProps {
  onSignIn(): void;
}

const LOGIN_ERROR = "login_error=true";

const SignIn: React.FC<ISignInProps> = props => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [oldSchoolFlow, setOldSchoolFlow] = useState<boolean>(true);
  const isFromSuccessfulPasswordReset =
    props.location &&
    props.location &&
    props.location.state &&
    props.location.state.isFromSuccessfulPasswordReset;

  useEffect(() => {
    if (
      props.location &&
      props.location.search &&
      props.location.search.includes("rwRedirect")
    ) {
      const queryParams = new URLSearchParams(props.location?.search);
      queryParams.delete("rwRedirect");
      navigate(routes.toRwSignUpPath + "?" + queryParams.toString());    
    }
    if (
      props.location &&
      props.location.search &&
      props.location.search.includes(LOGIN_ERROR)
    ) {
      setErrorMessage("Invalid email or password");
    }

    agent.Auth.setCookie()
      .then(agent.Auth.checkCookie)
      .then(res => {
        if (res.data.cookie_match) {
          setOldSchoolFlow(false);
        }
      })
      .catch(err => {
        if (err && err.response) {
          setErrorMessage(err.response.data.errors[0].detail);
        } else {
          setErrorMessage("There was a problem signing in.");
        }
      });
  }, [props.location]);

  function handleSubmit(
    values: ISignInFormValues,
    actions: FormikActions<ISignInFormValues>
  ) {
    agent.Auth.login(values.email, values.password)
      .then(res => {
        actions.setSubmitting(false);
        props.onSignIn();
      })
      .catch(err => {
        actions.setSubmitting(false);
        if (err && err.response) {
          setErrorMessage(err.response.data.errors[0].detail);
        } else {
          setErrorMessage("There was a problem signing in.");
        }
      });
  }

  return (
    <Layout>
      <Container className="pt-3">
        <div className="text-center">
          <img src={iconLogoC} alt="Rec Hub logo icon" className="mb-3" />
          <h1 className="text-center mb-3">Sign In</h1>
        </div>
        <Row>
          <Col sm={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
            <Card>
              <CardBody>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={formSchema}
                  onSubmit={handleSubmit}
                  render={(formikBag: FormikProps<ISignInFormValues>) => (
                    <Form
                      onSubmit={event => {
                        if (oldSchoolFlow) {
                          // default form submit
                        } else {
                          event.preventDefault();
                          formikBag.handleSubmit(event);
                        }
                      }}
                      action={`${API_BASE_URL}login`}
                      method="post"
                    >
                      <FormGroup>
                        <Label>Email</Label>
                        <Input
                          name="email"
                          type="email"
                          onChange={formikBag.handleChange}
                          placeholder="Email"
                          invalid={
                            !!(
                              formikBag.errors.email && formikBag.touched.email
                            )
                          }
                        />
                        <FormFeedback>{formikBag.errors.email}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>Password</Label>
                        <PasswordPeekInput
                          name="password"
                          type="password"
                          onChange={formikBag.handleChange}
                          error={formikBag.errors.password}
                          invalid={
                            !!(
                              formikBag.errors.password &&
                              formikBag.touched.password
                            )
                          }
                        />
                      </FormGroup>
                      <Input
                        type="hidden"
                        name="success_redirect_url"
                        value={props.location?.search.includes("rrToken") ? `${window.location.origin}${props.location?.search}` : `${window.location.origin}`}
                      />
                      <Input
                        type="hidden"
                        name="error_redirect_url"
                        value={props.location?.search.includes("rrToken") ? `${window.location.origin}/login${props.location?.search}&${LOGIN_ERROR}` : `${window.location.origin}/login?${LOGIN_ERROR}`}
                      />
                      <FormGroup>
                        <p className="text-secondary">
                          By continuing, you agree to
                          <Link to={routes.toPrivacyPath}>
                            {" "}
                            RECHUB's Privacy Policy & Terms of Use.
                          </Link>
                        </p>
                      </FormGroup>
                      <Button
                        type="submit"
                        color="primary"
                        disabled={formikBag.isSubmitting}
                      >
                        Sign In
                      </Button>
                      {errorMessage && (
                        <Alert color="danger" className="mt-3">
                          {errorMessage}
                        </Alert>
                      )}
                      {isFromSuccessfulPasswordReset && (
                        <Alert color="success" className="mt-3">
                          Your Password has been succesfully changed.
                        </Alert>
                      )}
                    </Form>
                  )}
                />
              </CardBody>
              <CardBody>
                <p>
                  Forgot password?{" "}
                  <Link to={routes.toForgotPasswordPath}>Click here</Link>
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default SignIn;
