import React from "react";

import { Formik, FormikActions, FormikProps } from "formik";
import * as yup from "yup";

import {
  Form,
  FormFeedback,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  CustomInput,
} from "reactstrap";
import { IAccountSettings } from "../interfaces";
import { AxiosPromise } from "axios";

export interface IEmailFrequencyFormValues {
  send_notification_emails: boolean;
}

const formSchema = yup.object().shape({
  send_notification_emails: yup
    .boolean()
    .label("Send Notification Emails")
    .required(),
});

interface IPrimaryContactFormProps {
  className?: string;
  isOpen: boolean;
  accountData: IAccountSettings;
  onClose(): void;
  onSave(accountData: IAccountSettings): AxiosPromise<IAccountSettings>;
}

const EmailNotificationModal: React.FC<IPrimaryContactFormProps> = props => {
  function handleSubmit(
    values: IEmailFrequencyFormValues,
    actions: FormikActions<IEmailFrequencyFormValues>
  ) {
    const accountData = {
      ...props.accountData,
      ...values,
    };
    props.onSave(accountData).then(() => {
      actions.setSubmitting(false);
      props.onClose();
    });
  }

  return (
    <Formik
      initialValues={{
        send_notification_emails: props.accountData.send_notification_emails,
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<IEmailFrequencyFormValues>) => (
        <Modal
          isOpen={props.isOpen}
          toggle={props.onClose}
          className={props.className}
        >
          <Form onSubmit={formikBag.handleSubmit}>
            <ModalHeader toggle={props.onClose}>Notifications</ModalHeader>
            <ModalBody className="rh-bg-teal">
              <p>Enable or disable notification emails.</p>

              <FormGroup>
                <CustomInput
                  type="checkbox"
                  name="send_notification_emails"
                  id="send_notification_emails"
                  label="Send notification emails"
                  checked={formikBag.values.send_notification_emails}
                  onChange={formikBag.handleChange}
                />

                <FormFeedback>
                  {formikBag.errors.send_notification_emails}
                </FormFeedback>
              </FormGroup>
            </ModalBody>
            <ModalFooter className="rh-bg-teal text-right">
              <Button disabled={formikBag.isSubmitting} onClick={props.onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={formikBag.isSubmitting}
              >
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    />
  );
};

export default EmailNotificationModal;
