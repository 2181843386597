import React, { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
import ReactMarkdown from "react-markdown";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { RouteComponentProps } from "@reach/router";
import {
  Table,
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import UserSettingsContext from "../context/user-settings-context";
import agent from "../agent";
import Layout from "../components/Layout";
import { IDashboardStats, IContentfulPage, IUniversity } from "../interfaces";

import iconInvites from "../images/icon-invites.svg";
import iconResume from "../images/icon-resume.svg";
import iconFiles from "../images/icon-files.svg";

import CircularProgress from "../components/CircularProgress";
import ContentfulTips from "../components/ContentfulTips";
import Notifications from "../components/Notifications";
import LinkRouteAware from "../components/LinkRouteAware";

import * as routes from "../routes";
dayjs.extend(utc);
dayjs.extend(relativeTime);

interface IDashboardProps extends RouteComponentProps {
  pageContent?: IContentfulPage;
  universityList: IUniversity[];
}

const Dashboard: React.FC<IDashboardProps> = props => {
  const { user, userUniversityName } = useContext(UserSettingsContext);
  const { pageContent } = props;
  const [stats, setStats] = useState<IDashboardStats | null>(null);
  useEffect(() => {
    agent.Dashboard.get().then(res => {
      if (res.data) {
        setStats(res.data);
      }
    });
  }, []);

  function getPercentage(portion: number, total: number) {
    return Math.round((portion / total) * 100);
  }

  function calculateFilesPercentage() {
    let p = 0;
    if (stats && stats.files.profile_photo) {
      p = 100;
    }
    return p;
  }

  return (
    <Layout>
      {user && user.id && (
        <Container className="rh-main-container">
          <h1>
            {pageContent && pageContent.title ? pageContent.title : "Dashboard"}
          </h1>
          {pageContent && pageContent.instructions && (
            <ReactMarkdown source={pageContent.instructions} className="mb-1" />
          )}
          {/* <p className="mb-5">
            Welcome to RecHub, {settings.first_name || user.email}
          </p> */}
          <div className="d-md-flex justify-content-between align-items-center">
            <div>
              <h3 className="mb-0 text-dark">Activity</h3>
            </div>
            <div>
              <Table borderless={true} size="sm" className="mb-0">
                <tbody>
                  <tr>
                    <td>
                      <p className="mb-0 text-uppercase" id="invitesInProgress">
                        {stats && (
                          <Badge
                            color="primary"
                            className="rh-badge-circle text-white mr-2"
                          >
                            {stats.rec_invites.total_invites -
                              stats.rec_invites.rec_invites_sent}
                          </Badge>
                        )}
                        Invites in progress
                      </p>
                      <UncontrolledTooltip target="invitesInProgress">
                        Total number of invitations started but not yet sent
                      </UncontrolledTooltip>
                    </td>
                    <td>
                      <p className="mb-0 ml-4 text-uppercase" id="invitesSent">
                        {stats && (
                          <Badge
                            color="primary"
                            className="rh-badge-circle text-white mr-2"
                          >
                            {stats.rec_invites.rec_invites_sent}
                          </Badge>
                        )}
                        Invites sent
                      </p>
                      <UncontrolledTooltip target="invitesSent">
                        Total number of invitations sent
                      </UncontrolledTooltip>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="mb-0 text-uppercase" id="recsInProgress">
                        {stats && (
                          <Badge
                            color="primary"
                            className="rh-badge-circle text-white mr-2"
                          >
                            {stats.rec_invites.recs_in_progress}
                          </Badge>
                        )}
                        Recs in progress
                      </p>
                      <UncontrolledTooltip target="recsInProgress">
                        Total number of invitations accepted and recommendations
                        underway
                      </UncontrolledTooltip>
                    </td>
                    <td>
                      <p
                        className="mb-0 ml-4 text-uppercase"
                        id="recsSentToSorority"
                      >
                        {stats && (
                          <Badge
                            color="primary"
                            className="rh-badge-circle text-white mr-2"
                          >
                            {stats.rec_invites.recs_sent_to_sorority}
                          </Badge>
                        )}
                        Recs Complete
                      </p>
                      <UncontrolledTooltip target="recsSentToSorority">
                        Total number of recommendations completed and sent to
                        the sorority
                      </UncontrolledTooltip>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>

          <hr className="mb-6" />
          <Row className="mb-5">
            <Col lg="4" xl="3" className="mb-6 mb-lg-0">
              <Card
                className="border-0"
                tag={LinkRouteAware}
                to={routes.toResume}
              >
                <CardHeader className="rh-bg-teal-dark border-0 text-center position-relative">
                  <div className="rh-card-header-icon">
                    <img src={iconResume} alt="resume laptop journal icon" />
                  </div>
                  <h3 className="h5 mb-0 text-p text-dark">Resumé</h3>
                </CardHeader>
                <CardBody className="rh-bg-teal text-center">
                  <div className="rh-circular-progress mx-auto mb-3">
                    <CircularProgress
                      percentage={
                        stats
                          ? getPercentage(
                              stats.resume.complete,
                              stats.resume.total
                            )
                          : 0
                      }
                      strokeWidth={2}
                      initialAnimation={true}
                    >
                      <div className="text-center">
                        <div className="d-flex">
                          <p className="rh-number display-1 text-info mb-3">
                            {stats
                              ? getPercentage(
                                  stats.resume.complete,
                                  stats.resume.total
                                )
                              : 0}
                          </p>
                          <p className="text-info">%</p>
                        </div>

                        <div className="rh-divider mb-2" />
                        <p className="mb-0 text-info">Complete</p>
                      </div>
                    </CircularProgress>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" xl="3" className="mb-6 mb-lg-0">
              <Card
                className="border-0"
                tag={LinkRouteAware}
                to={routes.filesPath}
              >
                <CardHeader className="rh-bg-teal-dark border-0 text-center position-relative">
                  <div className="rh-card-header-icon">
                    <img src={iconFiles} alt="files document check icon" />
                  </div>
                  <h3 className="h5 mb-0 text-dark">Files</h3>
                </CardHeader>
                <CardBody className="rh-bg-teal text-center">
                  <div className="rh-circular-progress mx-auto mb-3">
                    <CircularProgress
                      percentage={calculateFilesPercentage()}
                      strokeWidth={2}
                      initialAnimation={true}
                    >
                      <div className="text-center">
                        <div className="d-flex">
                          <p className="rh-number display-1 text-info mb-3">
                            {calculateFilesPercentage()}
                          </p>
                          <p className="text-info">%</p>
                        </div>

                        <div className="rh-divider mb-2" />
                        <p className="mb-0 text-info">Complete</p>
                      </div>
                    </CircularProgress>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" xl="3" className="mb-6 mb-lg-0">
              <Card
                className="border-0"
                tag={LinkRouteAware}
                to={routes.recommendationsPath}
              >
                <CardHeader className="rh-bg-teal-dark border-0 text-center position-relative">
                  <div className="rh-card-header-icon">
                    <img src={iconInvites} alt="invites airplane icon" />
                  </div>
                  <h3 className="h5 mb-0 text-dark">Recommendations</h3>
                </CardHeader>
                <CardBody className="rh-bg-teal text-center">
                  <p className="mb-0">School</p>
                  <p
                    className="mb-0 text-uppercase text-muted"
                    style={{ letterSpacing: "0.1em" }}
                  >
                    <small>{userUniversityName}</small>
                  </p>
                  <hr />
                  <div className="d-flex align-items-center justify-content-center">
                    <p
                      className="mt-4 mb-4 display-1 text-info"
                      style={{ lineHeight: "0.35" }}
                    >
                      {stats ? stats.rec_invites.recs_sent_to_sorority : 0}
                      <br />
                      <span className="h6">Recs&nbsp;Complete</span>
                    </p>
                    <p className="mx-3">of</p>
                    <p
                      className="mt-4 mb-4 display-1 text-info"
                      style={{ lineHeight: "0.35" }}
                    >
                      {stats ? stats.rec_invites.rec_invites_sent : 0}
                      <br />
                      <span className="h6">Invites&nbsp;Sent</span>
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3" className="d-none d-xl-block mb-3">
              <Notifications />
            </Col>
          </Row>
          <ContentfulTips pageContent={pageContent} />
        </Container>
      )}
    </Layout>
  );
};

export default Dashboard;
