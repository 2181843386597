import React, { useState, useEffect } from "react";
import axios, { AxiosResponse } from "axios";
import { RouteComponentProps, Link } from "@reach/router";
import printJS from "print-js";

import * as routes from "../../routes";

import agent from "../../agent";
import {
  IProfile,
  IAffiliation,
  IActivity,
  IAward,
  ICommunityActivity,
  IJob,
  IResume,
} from "../../interfaces";
import { Row, Col, Button, Alert, Spinner } from "reactstrap";
import { ResumePDFDownloadLink, ResumePDFViewer } from "./ResumePDF";
import usePDF from "./usePdf";

const ViewResume: React.FC<RouteComponentProps> = (props) => {
  useEffect(() => {
    fetchAllResumeData();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [resumeData, setResumeData] = useState<IResume>({
    profile: null,
    affiliations: [],
    activities: [],
    awards: [],
    communityActivities: [],
    jobs: [],
  });
  function fetchAllResumeData() {
    // apparently TypeScript does not like axios.all and axios.spread
    setIsLoading(true);
    axios
      .all([
        // @ts-ignore
        agent.Profile.get(),
        // @ts-ignore
        agent.GreekAffiliations.getAll(),
        // @ts-ignore
        agent.Activities.getAll(),
        // @ts-ignore
        agent.Awards.getAll(),
        // @ts-ignore
        agent.CommunityActivities.getAll(),
        // @ts-ignore
        agent.Jobs.getAll(),
      ])
      .then(
        // @ts-ignore
        axios.spread(function (
          profileRes: AxiosResponse<IProfile>,
          affiliationsRes: AxiosResponse<IAffiliation[]>,
          activitiesRes: AxiosResponse<IActivity[]>,
          awardsRes: AxiosResponse<IAward[]>,
          communityActivitiesRes: AxiosResponse<ICommunityActivity[]>,
          jobsRes: AxiosResponse<IJob[]>
        ) {
          setResumeData({
            profile: profileRes.data,
            affiliations: affiliationsRes.data,
            activities: activitiesRes.data,
            awards: awardsRes.data,
            communityActivities: communityActivitiesRes.data,
            jobs: jobsRes.data,
          });
          setIsLoading(false);
        })
      )
      .catch(() => setIsLoading(false));
  }

  const { documentURL } = usePDF(resumeData);

  return (
    <>
      <span className="d-print-none">
        <Row>
          <Col>
            <h3>View Your Resumé</h3>
          </Col>
          <Col sm="auto">
            <Button
              tag={Link}
              to={routes.toResumeEditPersonal}
              className="btn-slim mr-2"
            >
              <i className="fas fa-pencil-alt mr-2" /> Edit
            </Button>
            {resumeData && documentURL && (
              <Button
                onClick={() => {
                  printJS(documentURL);
                }}
                className="mr-2 btn-slim"
              >
                <i className="fas fa-print mr-2" /> Print
              </Button>
            )}

            {resumeData.profile !== null && (
              <ResumePDFDownloadLink resume={resumeData} />
            )}
          </Col>
        </Row>

        <hr />
      </span>
      {isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spinner
            as="span"
            animation="grow"
            size="lg"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}
      {!isLoading && !resumeData.profile && (
        <Alert color="danger">
          Unable To load resume please refresh your browser
        </Alert>
      )}
      {resumeData.profile && <ResumePDFViewer resume={resumeData} />}
    </>
  );
};

export default ViewResume;
