import React, { useState, useContext, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { Container, Row, Col, Card, CardBody, Button, Alert } from "reactstrap";
import dayjs from "dayjs";

import UserSettingsContext from "../context/user-settings-context";
import agent from "../agent";

import { IAccountSettings, IProfile } from "../interfaces";

import Layout from "../components/Layout";
import PrimaryContactFormModal from "../components/PrimaryContactEditModal";
import SecondaryContactFormModal from "../components/SecondaryContactEditModal";
import EmailNotificationModal from "../components/EmailNotificationModal";
import PasswordFormModal from "../components/PasswordFormModal";

const Account: React.FC<RouteComponentProps> = props => {
  const { settings, updateSettings, user } = useContext(UserSettingsContext);
  const [profile, setProfile] = useState<IProfile | null>(null);
  const isPnm = user.user_type === "pnm";
  useEffect(() => {
    if (!isPnm) return;
    agent.Profile.get().then(res => {
      if (res.data) {
        setProfile(res.data);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [passwordModalIsOpen, setPasswordModalIsOpen] = useState(false);
  function updatePassword(password: string) {
    return agent.User.updatePassword(password).catch(err => {
      console.error(err.response);
      return err;
    });
  }
  const [primaryContactModalIsOpen, setPrimaryContactModalIsOpen] = useState(
    false
  );
  const [
    secondaryContactModalIsOpen,
    setSecondaryContactModalIsOpen,
  ] = useState(false);

  const [
    emailNotificationModalIsOpen,
    setEmailNotificationModalIsOpen,
  ] = useState(false);

  function saveAccountData(data: IAccountSettings) {
    return agent.User.updateSettings(data)
      .then(res => {
        if (res.data) {
          updateSettings(res.data);
        }
        return res;
      })
      .catch(err => {
        console.error(err.response);
        return err;
      });
  }

  return (
    <Layout>
      <Container className="rh-main-container">
        <h1 className="mb-5">Account</h1>
        {settings && (
          <>
            <Row>
              <Col sm="6">
                {isPnm && user.paid &&
                <>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h2 className="mb-0">Primary Contact</h2>
                  <Button
                    color="link"
                    className="btn-slim"
                    onClick={() => setPrimaryContactModalIsOpen(true)}
                  >
                    <i className="fas fa-pencil-alt mr-2" /> Edit
                  </Button>
                </div>
                
                <Card className="rh-bg-teal border-0 mb-3">
                  <CardBody>
                    <Row>
                      <Col sm="6">
                        <p>
                          <strong>Full Name</strong> (account holder)
                        </p>
                      </Col>
                      <Col sm="6">
                        <p>
                          {settings.first_name} {settings.last_name}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <p className="mb-0">
                          <strong>Email Address</strong>
                        </p>
                      </Col>
                      <Col sm="6">
                        <p className="mb-0">{settings.email}</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                </>
                }
                {isPnm && user.paid && (
                  <>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <h2 className="mb-0">Secondary Contact</h2>

                      <Button
                        color="link"
                        className="btn-slim"
                        onClick={() => setSecondaryContactModalIsOpen(true)}
                      >
                        <i className="fas fa-pencil-alt mr-2" /> Edit
                      </Button>
                    </div>
                    <p>
                      Secondary Contacts such as parents or guardians will
                      receive a copy of the same email notifications as the
                      Primary Contact.
                    </p>
                  </>
                )}
                {isPnm && user.paid && (
                  <Card className="rh-bg-teal border-0 mb-3">
                    <CardBody>
                      <Row>
                        <Col sm="6">
                          <p>
                            <strong>Full Name</strong> (parent/guardian)
                          </p>
                        </Col>
                        <Col sm="6">
                          <p>{settings.secondary_full_name}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <p className="mb-0">
                            <strong>Email Address</strong>
                          </p>
                        </Col>
                        <Col sm="6">
                          <p className="mb-0">{settings.secondary_email}</p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                )}
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h2 className="mb-0">Security Credentials</h2>
                  <Button
                    color="link"
                    className="btn-slim"
                    onClick={() => setPasswordModalIsOpen(true)}
                  >
                    <i className="fas fa-pencil-alt mr-2" /> Edit
                  </Button>
                </div>
                <Card className="rh-bg-teal border-0 mb-3">
                  <CardBody>
                    <Row>
                      <Col sm="6">
                        <p className="mb-0">
                          <strong>Password</strong>
                        </p>
                      </Col>
                      <Col sm="6">
                        <p className="mb-0">********</p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {((isPnm && user.paid) || (!isPnm)) && (
                  <>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <h2 className="mb-0">Email Notifications</h2>
                      <Button
                        color="link"
                        className="btn-slim"
                        onClick={() => setEmailNotificationModalIsOpen(true)}
                      >
                        <i className="fas fa-pencil-alt mr-2" /> Edit
                      </Button>
                    </div>
                    <Card className="rh-bg-teal border-0 mb-3">
                      <CardBody>
                        <Row>
                          <Col sm="6">
                            <p className="mb-0">
                              <strong>Email Notifications</strong>
                            </p>
                          </Col>
                          <Col sm="6">
                            <p className="mb-0">
                              {settings.send_notification_emails
                                ? "Enabled"
                                : "Disabled"}
                            </p>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </>
                )}
              </Col>
              {isPnm && user.paid && (
                <Col sm="6">
                  <h2 className="mb-3">Purchase Receipt</h2>
                  <Card>
                    <CardBody>
                      <p>
                        <span className="font-weight-bold">
                          Thank you for subscribing to Rec Hub!
                        </span>
                        <br />
                        <span className="text-muted">
                          Your subscription started on{" "}
                          {user.payment_info &&
                            dayjs(user.payment_info.created_at).format(
                              "MMM D, YYYY"
                            )}
                        </span>
                      </p>
                      <Row>
                        <Col>
                          <p>
                            <span className="font-weight-bold">
                              Invoice for:
                            </span>
                            <br />
                            <span className="text-muted">
                              {profile && profile.name}
                            </span>
                          </p>
                        </Col>
                        <Col sm="auto">
                          <p className="h1">${settings.amount_paid}</p>
                        </Col>
                      </Row>
                      <Alert className="rh-bg-teal">
                        <span className="font-weight-bold mr-2">Duration:</span>
                        Your account is now active and will expire on{" "}
                        {user.payment_info &&
                          dayjs(user.payment_info.updated_at)
                            .add(9, "month")
                            .format("MMM D, YYYY")}
                      </Alert>
                      <p className="text-muted">
                        Note: This receipt confirms your payment of $
                        {settings.amount_paid} to REC HUB. A copy of this
                        receipt was sent to {settings.email}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
            <PrimaryContactFormModal
              accountData={settings}
              isOpen={primaryContactModalIsOpen}
              onClose={() => setPrimaryContactModalIsOpen(false)}
              onSave={saveAccountData}
            />
            {isPnm && user.paid && (
              <SecondaryContactFormModal
                accountData={settings}
                isOpen={secondaryContactModalIsOpen}
                onClose={() => setSecondaryContactModalIsOpen(false)}
                onSave={saveAccountData}
              />
            )}
            <PasswordFormModal
              isOpen={passwordModalIsOpen}
              onClose={() => setPasswordModalIsOpen(false)}
              onSave={updatePassword}
            />
            <EmailNotificationModal
              accountData={settings}
              isOpen={emailNotificationModalIsOpen}
              onClose={() => setEmailNotificationModalIsOpen(false)}
              onSave={saveAccountData}
            />
          </>
        )}
      </Container>
    </Layout>
  );
};

export default Account;
