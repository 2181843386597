import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Container } from "reactstrap";
import Layout from "../components/Layout";
import WizardSteps from "../components/WizardSteps";
import iconLogoC from "../images/icon-logo-c.svg";
import { IUniversity, IApiResult } from "../interfaces";
import { rwSignUpSteps } from "../constants";
import SignUpComplete from "../components/SignUpComplete";
import CreateAccountForm, {
  ICreateAccountFormValues,
} from "../components/CreateAccountForm";
import agent from "../agent";
import { toSignIn } from "../routes";

interface IRWSignUpProps extends RouteComponentProps {
  universityList: IUniversity[];
}

function capitalize(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

const RWSignUp: React.FC<IRWSignUpProps> = props => {
  const [signUpStep, setSignUpStep] = useState<number>(0);

  const queryParams = new URLSearchParams(props.location?.search);
  const token = queryParams.get("rrToken");

  function createRWAccount(
    formValues: ICreateAccountFormValues
  ): Promise<IApiResult> {
    return agent.User.createAccount(formValues)
      .then(res => {
        console.log("user account created:", res);
        setSignUpStep(1);
        return {
          message: "Account created",
          data: res.data,
        };
      })
      .catch(err => {
        console.log("CreateAccount err:", err.response);
        let errorMessage = "There was a problem creating your account.";
        const { errors } = err.response.data;
        // process errors for display
        // errors is an array
        // each error item has a detail object that look like this:
        // { email: "is already taken" }
        // for now we'll just display the first error
        if (errors && errors[0].detail) {
          errorMessage = Object.keys(formValues).reduce((acc, curr) => {
            if (errors[0].detail[curr]) {
              const msg = `${capitalize(curr)} ${errors[0].detail[curr]}`;
              return `${acc}${msg}`;
            } else {
              return acc;
            }
          }, "");
        }

        return {
          error: true,
          message: errorMessage,
        };
      });
  }

  return (
    <Layout>
      <Container className="pt-3">
        <div className="text-center">
          <img src={iconLogoC} alt="Rec Hub logo icon" className="mb-3" />
          <h1 className="text-center mb-3">
            Sign Up for Recommendation Writers
          </h1>
          <p>
            Do you already have an account? <a href={toSignIn + props.location?.search}>Click here</a> to
            sign in
          </p>
        </div>
        <WizardSteps
          className="mb-4"
          currentStepIndex={signUpStep}
          steps={rwSignUpSteps}
        />
        {props.universityList && signUpStep === 0 && (
          <CreateAccountForm
            onSubmit={createRWAccount}
            isRWAccount
            rrToken={token!}
            universityList={props.universityList}
          />
        )}
        {signUpStep === 1 && <SignUpComplete />}
      </Container>
    </Layout>
  );
};

export default RWSignUp;
