import React from "react";
import { IResume, ICommunityActivity } from "../../interfaces";
import { Row, Col, Table } from "reactstrap";
import dayjs from "dayjs";

interface Props {
  resume: IResume;
}

const ViewDetails = ({
  resume: {
    profile,
    university,
    affiliations,
    activities,
    awards,
    communityActivities,
    jobs,
  },
}: Props) => {
  return (
    <>
      {profile && (
        <Row>
          <Col sm="3" className="rh-bg-teal p-4">
            <h3>Personal</h3>
            <p>
              <span className="font-weight-bold">Phone</span>
              <br />
              {profile.mobile_number}
            </p>
            <p>
              <span className="font-weight-bold">Email</span>
              <br />
              {profile.pnm_email}
            </p>
            <p>
              <span className="font-weight-bold">Address</span>
              <br />
              {profile.street_address}
            </p>
            {profile.date_of_birth && (
              <p>
                <span className="font-weight-bold">Birth Date</span>
                <br />
                {dayjs(profile.date_of_birth).format("MMMM D, YYYY")}
              </p>
            )}

            <div>
              <span className="font-weight-bold">Social Media</span>
              <br />

              {profile.facebook_url && (
                <div>
                  <span className="text-secondary">Facebook: </span>
                  <span>{profile.facebook_url}</span>
                </div>
              )}
              {profile.instagram_name && (
                <div>
                  <span className="text-secondary">Instagram: </span>
                  <span>{profile.instagram_name}</span>
                </div>
              )}
              {profile.snapchat_name && (
                <div>
                  <span className="text-secondary">Snapchat: </span>
                  <span>{profile.snapchat_name}</span>
                </div>
              )}
              {profile.twitter_name && (
                <div>
                  <span className="text-secondary">Twitter: </span>
                  <span>{profile.twitter_name}</span>
                </div>
              )}
            </div>
          </Col>
          <Col className="flex-grow-1 p-4">
            <Row className="align-items-center">
              <Col xs="auto">
                {profile.profile_photo_url ? (
                  <img
                    src={`${profile.profile_photo_url}`}
                    alt="Resume profile"
                    className="mx-auto rh-bg-teal rounded-circle d-flex align-items-center justify-content-center mb-3"
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <div
                    className="rh-bg-teal rounded-circle d-flex align-items-center justify-content-center"
                    style={{ width: "150px", height: "150px" }}
                  >
                    <p className="mb-0">Photo</p>
                  </div>
                )}
              </Col>
              <Col>
                <h2 className="display-4 mb-0">{profile.name}</h2>
                {profile.preferred_name && (
                  <span className="h3">"{profile.preferred_name}"</span>
                )}
              </Col>
            </Row>

            <hr />

            <h3 className="mb-3">Education</h3>
            <Table size="sm" borderless>
              <tbody>
                <tr>
                  <td className="pl-0" colSpan={2}>
                    <h4>College/University Attending</h4>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0">College/University Name</td>
                  <td>{profile ? profile.university : ""}</td>
                </tr>
                <tr>
                  <td className="pl-0">Intended Major</td>
                  <td>{profile.intended_major}</td>
                </tr>
                <tr>
                  <td className="pl-0">Entering university or college as</td>
                  <td>{profile.entering_as}</td>
                </tr>
                {profile.transfer_from && (
                  <tr>
                    <td className="pl-0">Transferring from</td>
                    <td>{profile.transfer_from}</td>
                  </tr>
                )}
                <tr>
                  <td className="pl-0" colSpan={2}>
                    <h4 className="pt-3">High School</h4>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0">High School Name</td>
                  <td>{profile.hs_name}</td>
                </tr>
                <tr>
                  <td className="pl-0">High School Full Address</td>
                  <td>{profile.hs_address}</td>
                </tr>
                <tr>
                  <td className="pl-0">Curriculum</td>
                  <td>{profile.curriculum && profile.curriculum.join(", ")}</td>
                </tr>
                <tr>
                  <td className="pl-0">GPA</td>
                  <td>{profile.hs_gpa}</td>
                </tr>
                <tr>
                  <td className="pl-0">GPA Scale</td>
                  <td>{profile.hs_gpa_scale}</td>
                </tr>
                <tr>
                  <td className="pl-0">Class Size</td>
                  <td>{profile.hs_class_size}</td>
                </tr>
                <tr>
                  <td className="pl-0">Class Rank</td>
                  <td>
                    {profile.hs_class_rank === null
                      ? "N/A"
                      : profile.hs_class_rank}
                  </td>
                </tr>
                {profile.act_score && (
                  <tr>
                    <td className="pl-0">ACT Score</td>
                    <td>{profile.act_score}</td>
                  </tr>
                )}
                {profile.sat_score && (
                  <tr>
                    <td className="pl-0">SAT Score</td>
                    <td>{profile.sat_score}</td>
                  </tr>
                )}
                <tr>
                  <td className="pl-0" colSpan={2}>
                    <h4 className="pt-3">Family</h4>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0" colSpan={2}>
                    <h5 className="font-weight-bold">Mother</h5>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0">Full Name</td>
                  <td>{profile.mother_name}</td>
                </tr>
                <tr>
                  <td className="pl-0">Maiden Name</td>
                  <td>{profile.mother_maiden_name}</td>
                </tr>
                <tr>
                  <td className="pl-0">Profession</td>
                  <td>{profile.mother_profession}</td>
                </tr>
                <tr>
                  <td className="pl-0">
                    Did she attend a College or University?
                  </td>
                  <td>
                    {profile.mother_college
                      ? `Yes, ${profile.mother_college}`
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <td className="pl-0">Did she obtain a degree?</td>
                  <td>
                    {profile.mother_degree
                      ? `Yes, ${profile.mother_degree}`
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <td className="pl-0" colSpan={2}>
                    <h5 className="font-weight-bold">Father</h5>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0">Full Name</td>
                  <td>{profile.father_name}</td>
                </tr>
                <tr>
                  <td className="pl-0">Profession</td>
                  <td>{profile.father_profession}</td>
                </tr>
                <tr>
                  <td className="pl-0">
                    Did he attend a College or University?
                  </td>
                  <td>
                    {profile.father_college
                      ? `Yes, ${profile.father_college}`
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <td className="pl-0">Did he obtain a degree?</td>
                  <td>
                    {profile.father_degree
                      ? `Yes, ${profile.father_degree}`
                      : "No"}
                  </td>
                </tr>
                {!profile.opt_out_greek_affiliations &&
                  affiliations &&
                  affiliations.length > 0 && (
                    <>
                      <tr>
                        <td className="pl-0" colSpan={2}>
                          <h4 className="pt-3">Greek Affiliations</h4>
                        </td>
                      </tr>
                      {affiliations &&
                        //@ts-ignore help! not sure why TS doesn't like this .map()
                        affiliations.map((a: IAffiliation, index) => (
                          <React.Fragment key={a.id}>
                            <tr>
                              <td className="pl-0">Full Name</td>
                              <td>{a.name}</td>
                            </tr>
                            <tr>
                              <td className="pl-0">Relationship</td>
                              <td>{a.relationship}</td>
                            </tr>
                            <tr>
                              <td className="pl-0">Sorority</td>
                              <td>{a.sorority}</td>
                            </tr>
                            <tr>
                              <td className="pl-0">
                                College or University Campus
                              </td>
                              <td>{a.campus}</td>
                            </tr>
                            <tr>
                              <td className="pl-0">City, State</td>
                              <td>
                                {a.city}, {a.state}
                              </td>
                            </tr>
                            <tr>
                              <td className="pl-0">Dates Attended</td>
                              <td>
                                {a.date_began} - {a.date_ended}
                              </td>
                            </tr>

                            <tr>
                              <td className="pl-0">Email Address</td>
                              <td>
                                {a.email}
                              </td>
                            </tr>
                            <tr
                              className={
                                affiliations.length > 1 &&
                                index < affiliations.length - 1
                                  ? "border-bottom"
                                  : ""
                              }>
                              <td className="pl-0">Phone Number</td>
                              <td>
                                {a.mobile_number}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                    </>
                  )}
                {!profile.opt_out_activities &&
                  activities &&
                  activities.length > 0 && (
                    <>
                      <tr>
                        <td className="pl-0" colSpan={2}>
                          <h4 className="pt-3">Activities &amp; Interests</h4>
                        </td>
                      </tr>
                      {activities &&
                        //@ts-ignore help! not sure why TS doesn't like this .map()
                        activities.map((a: IActivity, index) => (
                          <React.Fragment key={a.id}>
                            <tr>
                              <td className="pl-0">Activity/Interest Name</td>
                              <td>{a.subject}</td>
                            </tr>
                            <tr>
                              <td className="pl-0">Leadership Role</td>
                              <td>{a.leadership_role}</td>
                            </tr>
                            <tr
                              className={
                                activities.length > 1 &&
                                index < activities.length - 1
                                  ? "border-bottom"
                                  : ""
                              }
                            >
                              <td className="pl-0">Years Participated</td>
                              <td>
                                {a.year_began} - {a.year_ended}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                    </>
                  )}
                {!profile.opt_out_awards && awards && awards.length > 0 && (
                  <>
                    <tr>
                      <td className="pl-0" colSpan={2}>
                        <h4 className="pt-3">Awards &amp; Honors</h4>
                      </td>
                    </tr>
                    {awards &&
                      //@ts-ignore help! not sure why TS doesn't like this .map()
                      awards.map((a: IAward, index) => (
                        <React.Fragment key={a.id}>
                          <tr>
                            <td className="pl-0">Title of Award/Honor</td>
                            <td>{a.title}</td>
                          </tr>
                          <tr>
                            <td className="pl-0">Organization</td>
                            <td>{a.organization}</td>
                          </tr>
                          <tr
                            className={
                              awards.length > 1 && index < awards.length - 1
                                ? "border-bottom"
                                : ""
                            }
                          >
                            <td className="pl-0">Year Awarded</td>
                            <td>{a.year}</td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </>
                )}
                {!profile.opt_out_community_activities &&
                  communityActivities &&
                  communityActivities.length > 0 && (
                    <>
                      <tr>
                        <td className="pl-0" colSpan={2}>
                          <h4 className="pt-3">Community Involvement</h4>
                        </td>
                      </tr>
                      {communityActivities &&
                        communityActivities.map(
                          (a: ICommunityActivity, index: number) => (
                            <React.Fragment key={a.id}>
                              <tr>
                                <td className="pl-0">Role</td>
                                <td>{a.role}</td>
                              </tr>
                              <tr>
                                <td className="pl-0">Activity/Event</td>
                                <td>{a.event}</td>
                              </tr>
                              <tr
                                className={
                                  communityActivities.length > 1 &&
                                  index < communityActivities.length - 1
                                    ? "border-bottom"
                                    : ""
                                }
                              >
                                <td className="pl-0">Description</td>
                                <td>{a.description}</td>
                              </tr>
                            </React.Fragment>
                          )
                        )}
                    </>
                  )}
                {!profile.opt_out_jobs && jobs && jobs.length > 0 && (
                  <>
                    <tr>
                      <td className="pl-0" colSpan={2}>
                        <h4 className="pt-3">Employment</h4>
                      </td>
                    </tr>
                    {jobs &&
                      //@ts-ignore help! not sure why TS doesn't like this .map()
                      jobs.map((j: IJob, index) => (
                        <React.Fragment key={j.id}>
                          <tr>
                            <td className="pl-0">Employer</td>
                            <td>{j.employer}</td>
                          </tr>
                          <tr>
                            <td className="pl-0">Position</td>
                            <td>{j.position}</td>
                          </tr>
                          <tr
                            className={
                              jobs.length > 1 && index < jobs.length - 1
                                ? "border-bottom"
                                : ""
                            }
                          >
                            <td className="pl-0">Dates</td>
                            <td>
                              {j.date_began} - {j.date_ended}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ViewDetails;
