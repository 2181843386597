import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps, navigate } from "@reach/router";
import { Card, CardBody, FormGroup, Input, Label, Button } from "reactstrap";

import * as routes from "../../routes";

import {
  IProfile,
  IAffiliation,
  IActivity,
  IAward,
  ICommunityActivity,
  IJob,
  IExtraCurriculars,
  IOrder,
} from "../../interfaces";
import JobForm from "./JobForm";
import agent from "../../agent";

interface IEditEmploymentProps extends RouteComponentProps {
  profile: IProfile;
  formIsDisabled: boolean;
  greekAffiliations: IAffiliation[] | null;
  activities: IActivity[] | null;
  awards: IAward[] | null;
  community: ICommunityActivity[] | null;
  jobs: IJob[] | null;
  onUpdateProfile(profile: IProfile): void;
  onSubmit(profile: IProfile): Promise<void>;
}

const emptyJob: IJob = {
  key: new Date().getMilliseconds(),
  employer: "",
  position: "",
  date_began: "",
  date_ended: "",
};

const EditEmployment: React.FC<IEditEmploymentProps> = props => {
  const { profile, formIsDisabled } = props;

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0);
    }
  }, []);
  const [optOut, setOptOut] = useState(profile.opt_out_jobs);
  function toggleOptOut() {
    if (optOut) {
      setOptOut(false);
      addBlankJob();
    } else {
      setOptOut(true);
      setJobs(null);
    }
  }

  function saveAndContinue() {
    // note: we mark the resume as "complete" -- the PDF will be generated and editing will be disabled
    agent.Profile.update({
      ...profile,
      opt_out_jobs: optOut,
      complete: true,
    }).then(res => {
      props.onUpdateProfile(res.data);
      navigate(routes.toResumeView);
    });
  }

  const [jobs, setJobs] = useState<IJob[] | null>(null);

  useEffect(() => {
    fetchJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchJobs() {
    agent.Jobs.getAll().then(res => {
      if (res.data && res.data.length > 0) {
        res.data.forEach((e, index) => {
          e.key = index;
        });
        res.data.forEach((e, index) => {
          e.order = index;
        });
        setJobs(res.data);
      } else if (res.data && res.data.length === 0 && !profile.opt_out_jobs) {
        addBlankJob();
      }
    });
  }

  function addBlankJob() {
    if (jobs) {
      setJobs([
        ...jobs,
        {
          ...emptyJob,
          key: jobs.length,
          order: jobs.length
        },
      ]);
    } else {
      setJobs([emptyJob]);
    }
  }

  function deleteJob(jobToDelete: IJob) {
    if (jobToDelete.id) {
      agent.Jobs.delete(jobToDelete.id).then(() => {
        if (jobs) {
          setJobs(jobs.filter(a => a.id !== jobToDelete.id));
        }
      });
    } else if (jobToDelete.key) {
      if (jobs) {
        setJobs(jobs.filter(a => a.key !== jobToDelete.key));
      }
    }
  }

  function saveJob(updatedJob: IJob) {
    if (updatedJob.id) {
      return agent.Jobs.update(updatedJob.id, updatedJob);
    } else {
      return agent.Jobs.create(updatedJob);
    }
  }

  function saveOrdering(jobs: IJob[]) {
    let extraCurriculars = {} as IExtraCurriculars;
    let newJobs = [] as IOrder[];

    jobs.forEach((j) => {
      let newExtra = {
        id: j.id,
        order: j.order
      } as IOrder;
      newJobs.push(newExtra);
    });

    extraCurriculars.jobs = newJobs;
    agent.ExtraCurriculars.order(extraCurriculars);
  }

  function reorderJob(updatedJob: IJob, direction: "up" | "down") {
    if (direction === "up" && updatedJob.order! > 0) {
      const updatedOrder = updatedJob.order! - 1;
      let aboveItem = jobs?.find(a => a.order === updatedOrder)!;
      
      aboveItem.order = updatedOrder + 1;
      updatedJob.order = updatedOrder;
    }

    if (direction === "down" && updatedJob.order! < (jobs?.length! - 1)) {
      const updatedOrder = updatedJob.order! + 1;
      let belowItem = jobs?.find(a => a.order === updatedOrder)!;
      belowItem.order = updatedOrder - 1;
      updatedJob.order = updatedOrder;
    }

    const sortedJobs = [...jobs!].sort((a,b) => (a.order! > b.order!) ? 1 : -1)!;
    setJobs(sortedJobs);

    return saveOrdering(sortedJobs);
  }

  return (
    <>
      <Card className="rh-bg-teal border-0 mb-2">
        <CardBody className="pb-1">
          <p className="mb-2">Please list any employment.</p>
          <FormGroup check className="mr-2">
            <Label check>
              <Input
                type="checkbox"
                name="optOut"
                checked={optOut === true}
                onChange={toggleOptOut}
                disabled={formIsDisabled}
              />{" "}
              I have no employment.
            </Label>
          </FormGroup>
        </CardBody>
        {!optOut && jobs && (
          <CardBody>
            {jobs.map((e, index) => (
              <JobForm
                key={e.key}
                employer={e}
                onSubmit={saveJob}
                onDelete={deleteJob}
                formIsDisabled={formIsDisabled}
                onReorder={reorderJob}
                upEnabled={e.order! > 0}
                downEnabled={e.order! < (jobs.length - 1)}
              />
            ))}
            <Button onClick={addBlankJob} disabled={formIsDisabled}>
              <i className="fas fa-plus mr-2" /> Add Another
            </Button>
          </CardBody>
        )}
        <CardBody className="d-flex justify-content-between">
          <Button
            tag={Link}
            to={routes.toResumeEditCommunityInvolvement}
            color="link"
            className="btn-slim"
          >
            <i className="fas fa-chevron-left mr-2" /> Back
          </Button>
          {formIsDisabled ? (
            <Button tag={Link} to={routes.toResumeView} color="primary">
              View and Print
            </Button>
          ) : (
            <Button onClick={saveAndContinue} color="primary">
              Finish
            </Button>
          )}
        </CardBody>
        <CardBody />
      </Card>
      <Link to={routes.toResumeEditEmployment}>
        Skip and come back to later
      </Link>
    </>
  );
};

export default EditEmployment;
