import React from "react";
import * as yup from "yup";
import { Formik, FormikProps, FormikActions } from "formik";
import {
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Button,
  Row,
  Col,
} from "reactstrap";

import { IAward } from "../../interfaces";
import { pastYearOptions } from "../../utils/formHelpers";
import { AxiosPromise } from "axios";

const formSchema = yup.object().shape({
  title: yup
    .string()
    .label("Title")
    .required(),
  organization: yup
    .string()
    .label("Organization")
    .required(),
  year: yup
    .string()
    .label("Year")
    .required(),
});

interface IAwardFormProps {
  award: IAward;
  formIsDisabled: boolean;
  upEnabled: boolean;
  downEnabled: boolean;
  onSubmit(award: IAward): AxiosPromise<IAward>;
  onDelete(award: IAward): void;
  onReorder(award: IAward, direction: "up" | "down"): void;
}

const AwardForm: React.FC<IAwardFormProps> = props => {
  const { award, formIsDisabled } = props;

  function handleSubmit(values: IAward, actions: FormikActions<IAward>) {
    props
      .onSubmit({
        ...values,
      })
      .then(() => {
        actions.setSubmitting(false);
        actions.resetForm(values);
      });
  }

  return (
    <Formik
      initialValues={{
        ...award,
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<IAward>) => (
        <Row>
        <Col md="1" xs="1" className="mt-5">
          <FormGroup>
            <span>
              <i className={!formIsDisabled && props.upEnabled ? "fas fa-arrow-up fa-md arrow-button" : "fas fa-arrow-up fa-md disabled-arrow-button"}
              onClick={!formIsDisabled && props.upEnabled ? () => props.onReorder(award, "up") : undefined}
              />
            </span>
          </FormGroup>
          <FormGroup>
            <span>
              <i className={!formIsDisabled && props.downEnabled ? "fas fa-arrow-down fa-md arrow-button" : "fas fa-arrow-down fa-md disabled-arrow-button"}
              onClick={!formIsDisabled && props.downEnabled ? () => props.onReorder(award, "down") : undefined}
              />
            </span>
          </FormGroup>
        </Col>
        <Col md="11" xs="10">
        <Form onSubmit={formikBag.handleSubmit}>
          <FormGroup>
            <Label>
              Title of Award or Honor <span className="text-danger">*</span>
            </Label>
            <Input
              name="title"
              value={formikBag.values.title || ""}
              onChange={formikBag.handleChange}
              invalid={!!(formikBag.errors.title && formikBag.touched.title)}
              disabled={formIsDisabled}
            />
            <FormFeedback>{formikBag.errors.title}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>
              Organization <span className="text-danger">*</span>
            </Label>
            <Input
              name="organization"
              value={formikBag.values.organization || ""}
              onChange={formikBag.handleChange}
              invalid={
                !!(
                  formikBag.errors.organization &&
                  formikBag.touched.organization
                )
              }
              disabled={formIsDisabled}
            />
            <FormFeedback>{formikBag.errors.organization}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>
              Year Awarded <span className="text-danger">*</span>
            </Label>
            <Input
              name="year"
              type="select"
              value={formikBag.values.year || ""}
              onChange={formikBag.handleChange}
              invalid={!!(formikBag.errors.year && formikBag.touched.year)}
              disabled={formIsDisabled}
            >
              <option value="" />
              {pastYearOptions(20).map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Input>
            <FormFeedback>{formikBag.errors.year}</FormFeedback>
          </FormGroup>

          <div className="d-flex justify-content-between">
            <Button
              className="mr-2"
              onClick={() => props.onDelete(award)}
              disabled={formIsDisabled}
            >
              Delete
            </Button>
            <Button
              color="primary"
              type="submit"
              className={formikBag.dirty ? "d-block" : "d-none"}
              disabled={formIsDisabled}
            >
              Save
            </Button>
          </div>
          <hr />
        </Form>
        </Col>
        </Row>
      )}
    />
  );
};

export default AwardForm;
