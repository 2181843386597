import React from "react";
import { Button } from "reactstrap";
import fileDownload from "js-file-download";
import agent from "../agent";
import jsZip from "jszip";
import { IResume } from "../interfaces";
import usePDF from "./resume/usePdf";

interface Props {
  recId: number;
  updateRecRequest(): void;
  resume: IResume;
}

const FileDownload = ({ recId, updateRecRequest, resume }: Props) => {
  const { blob } = usePDF(resume);
  return (
    <Button
    className="mobile-display-none"
      color="primary"
      onClick={() => {
        agent.Files.downloadRecFiles(recId)
          .then((response) => {
            const zip = new jsZip();
            // load zip from server
            zip.loadAsync(response.data).then((zipData) => {
              if (blob) {
                // add resume
                zipData.file(
                  `${resume.profile?.name} (Rec Hub Resume).pdf`,
                  blob
                );
                // generate new zip and trigger download
                zipData.generateAsync({ type: "blob" }).then((blob) => {
                  fileDownload(blob, "files.zip");
                });
              }
            });
          })
          .then(updateRecRequest);
      }}
    >
      Download All
    </Button>
  );
};

export default FileDownload;
