import React, { useContext, useState, useEffect } from "react";
import dayjs from "dayjs";
import { navigate, RouteComponentProps } from "@reach/router";
import {
  Container,
  Badge,
  Row,
  Col,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import {
  IContentfulPage,
  IRecRequestSummary,
  INotification,
  IInviteClaim,
} from "../interfaces";
import agent from "../agent";
import Layout from "../components/Layout";
import UserSettingsContext from "../context/user-settings-context";
import RecRequestCard from "../components/RecRequestCard";
import ContentfulTips from "../components/ContentfulTips";
import Notifications from "../components/Notifications";
import ReactMarkdown from "react-markdown";
import * as routes from "../routes";

export function getStatus(status: string) {
  const statusMap: { [key: string]: string } = {
    in_progress: "In Progress",
    sent: "Sent",
    unopened: "Unopened",
  };

  return statusMap[status];
}

interface IRecInvitesProps extends RouteComponentProps {
  pageContent?: IContentfulPage;
}

const RecRequests: React.FC<IRecInvitesProps> = props => {
  const { pageContent, location } = props;
  const error = location && location.state && location.state.error;
  const { user } = useContext(UserSettingsContext);
  const [searchText, setSearchText] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("asc");
  const [recRequests, setRecRequests] = useState<IRecRequestSummary[]>([]);
  useEffect(() => {
    console.log(props.location?.search);
    if (props.location?.search.includes("rrToken")) {
      const queryParams = new URLSearchParams(props.location?.search);
      const token = queryParams.get("rrToken");

      let claimToken = {
        invite_token: token,
        writer_id: user.id ?? 0
      } as IInviteClaim;

      agent.Invitations.claim(claimToken).then(res => {
        if (res && res.data && res.data.id) {
          navigate(routes.toRecDetail(res.data.id));
        } else {
          navigate(routes.dashboardPath);
        }
      });
    }
    agent.RecRequests.getAll().then(res => {
      setRecRequests(res.data.requests);
    });
  }, []);

  const [notifications, setNotifications] = useState<INotification[]>([]);

  useEffect(() => {
    agent.Notifications.getAll().then(res => {
      setNotifications(res.data);
    });
  }, []);

  const filteredRecRequests = (searchText !== ""
    ? recRequests.filter(r => {
        return (
          `${r.pnm.first_name} ${r.pnm.last_name}`
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) > -1
        );
      })
    : recRequests
  ).sort((a, b) => {
    const firstRequestDeadline = dayjs(a.deadline);
    const secondRequestDeadline = dayjs(b.deadline);
    if (sortOrder === "desc") {
      return firstRequestDeadline.isBefore(secondRequestDeadline) ? 1 : -1;
    } else {
      return firstRequestDeadline.isAfter(secondRequestDeadline) ? 1 : -1;
    }
  });
  return (
    <Layout>
      {user && user.id && (
        <Container className="rh-main-container">
          {error && (
            <UncontrolledAlert color="danger">{error}</UncontrolledAlert>
          )}
          <div className="d-md-flex justify-content-between align-items-center pb-2">
            <h1>
              {pageContent && pageContent.title
                ? pageContent.title
                : "Rec Requests"}
            </h1>

            <div>
              <p className="mb-0 text-uppercase text-right">
                <Badge
                  color="primary"
                  className="rh-badge-circle text-white mr-2"
                >
                  {recRequests.length}
                </Badge>
                Received
                <Badge
                  color="primary"
                  className="rh-badge-circle text-white mx-2"
                >
                  {notifications.length}
                </Badge>
                Alerts
              </p>
            </div>
          </div>
          {pageContent && pageContent.instructions && (
            <ReactMarkdown source={pageContent.instructions} className="mb-1" />
          )}
          <div className="border-bottom" />
          <Row className="py-3 justify-content-between">
            <Col xs="10" md="4">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="bg-white border-right-0">
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  onChange={e => setSearchText(e.target.value)}
                  value={searchText}
                  placeholder="Search PNM Profiles"
                />
              </InputGroup>
            </Col>
            <Col xs="2" md="4">
              <InputGroup className="align-items-center d-none d-md-flex">
                <Label for="pnmSort" className="mb-0 pr-1">
                  Sort by
                </Label>
                <Input
                  type="select"
                  onChange={e => setSortOrder(e.target.value)}
                >
                  <option value="asc">Deadline Soonest First</option>
                  <option value="desc">Deadline Latest First</option>
                </Input>
              </InputGroup>
              <Button
                outline
                size="sm"
                className="d-block d-md-none rounded px-3"
              >
                <i className="fas fa-filter" />
              </Button>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col lg="4" xl="3" className="order-lg-2 mb-3">
              <Notifications />
            </Col>
            <Col lg="8" xl="9" className="order-lg-1">
              <Row>
                {filteredRecRequests.map(recRequest => (
                  <Col lg="6" xl="4" className="mb-3" key={recRequest.id}>
                    <RecRequestCard
                      id={recRequest.id}
                      progress={recRequest.progress}
                      photoUrl={recRequest.pnm.profile_image || ""}
                      status={getStatus(recRequest.status)}
                      name={
                        `${recRequest.pnm.first_name} ${
                          recRequest.pnm.last_name
                        }` || ""
                      }
                      university={recRequest.university.name}
                      deadline={dayjs(recRequest.deadline).format(
                        "MMMM D, YYYY"
                      )}
                      sorority={recRequest.sorority.name}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <ContentfulTips pageContent={pageContent} />
        </Container>
      )}
    </Layout>
  );
};

export default RecRequests;
