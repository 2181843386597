import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps, navigate } from "@reach/router";
import { Card, CardBody, FormGroup, Input, Label, Button } from "reactstrap";

import * as routes from "../../routes";

import { IProfile, ICommunityActivity, IExtraCurriculars, IOrder } from "../../interfaces";
import CommunityActivityForm from "./CommunityActivityForm";
import agent from "../../agent";

interface IEditCommunityActivitiesProps extends RouteComponentProps {
  profile: IProfile;
  formIsDisabled: boolean;
  onUpdateProfile(profile: IProfile): void;
  onSubmit(profile: IProfile): Promise<void>;
}

const emptyCommunityActivity: ICommunityActivity = {
  key: new Date().getMilliseconds(),
  role: "",
  event: "",
  description: "",
};

const EditCommunityActivities: React.FC<
  IEditCommunityActivitiesProps
> = props => {
  const { profile, formIsDisabled } = props;

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0);
    }
  }, []);
  const [optOut, setOptOut] = useState(profile.opt_out_community_activities);
  function toggleOptOut() {
    if (optOut) {
      setOptOut(false);
      addBlankCommunityActivity();
    } else {
      setOptOut(true);
      setCommunityActivities(null);
    }
  }

  function saveAndContinue() {
    agent.Profile.update({
      ...profile,
      opt_out_community_activities: optOut,
    }).then(res => {
      props.onUpdateProfile(res.data);
      navigate(routes.toResumeEditEmployment);
    });
  }

  const [communityActivities, setCommunityActivities] = useState<
    ICommunityActivity[] | null
  >(null);

  useEffect(() => {
    fetchCommunityActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchCommunityActivities() {
    agent.CommunityActivities.getAll().then(res => {
      if (res.data && res.data.length > 0) {
        res.data.forEach((e, index) => {
          e.key = index;
        });
        res.data.forEach((e, index) => {
          e.order = index;
        });
        setCommunityActivities(res.data);
      } else if (
        res.data &&
        res.data.length === 0 &&
        !profile.opt_out_community_activities
      ) {
        addBlankCommunityActivity();
      }
    });
  }

  function addBlankCommunityActivity() {
    if (communityActivities) {
      setCommunityActivities([
        ...communityActivities,
        {
          ...emptyCommunityActivity,
          key: communityActivities.length,
          order: communityActivities.length,
        },
      ]);
    } else {
      setCommunityActivities([emptyCommunityActivity]);
    }
  }

  function deleteCommunityActivity(
    communityActivityToDelete: ICommunityActivity
  ) {
    if (communityActivityToDelete.id) {
      agent.CommunityActivities.delete(communityActivityToDelete.id).then(
        res => {
          if (communityActivities) {
            setCommunityActivities(
              communityActivities.filter(
                a => a.id !== communityActivityToDelete.id
              )
            );
          }
        }
      );
    } else if (communityActivityToDelete.key) {
      if (communityActivities) {
        setCommunityActivities(
          communityActivities.filter(
            a => a.key !== communityActivityToDelete.key
          )
        );
      }
    }
  }

  function saveCommunityActivity(updatedCommunityActivity: ICommunityActivity) {
    if (updatedCommunityActivity.id) {
      return agent.CommunityActivities.update(
        updatedCommunityActivity.id,
        updatedCommunityActivity
      );
    } else {
      return agent.CommunityActivities.create(updatedCommunityActivity);
    }
  }

  function saveOrdering(activities: ICommunityActivity[]) {
    let extraCurriculars = {} as IExtraCurriculars;
    let newActivities = [] as IOrder[];

    activities.forEach((a) => {
      let newActivity = {
        id: a.id,
        order: a.order
      } as IOrder;
      newActivities.push(newActivity);
    });

    extraCurriculars.community_activities = newActivities;
    agent.ExtraCurriculars.order(extraCurriculars);
  }

  function reorderCommunityActivity(updatedActivity: ICommunityActivity, direction: "up" | "down") {
    if (direction === "up" && updatedActivity.order! > 0) {
      const updatedOrder = updatedActivity.order! - 1;
      let aboveItem = communityActivities?.find(a => a.order === updatedOrder)!;
      
      aboveItem.order = updatedOrder + 1;
      updatedActivity.order = updatedOrder;
    }

    if (direction === "down" && updatedActivity.order! < (communityActivities?.length! - 1)) {
      const updatedOrder = updatedActivity.order! + 1;
      let belowItem = communityActivities?.find(a => a.order === updatedOrder)!;
      belowItem.order = updatedOrder - 1;
      updatedActivity.order = updatedOrder;
    }

    const sortedActivities = [...communityActivities!].sort((a,b) => (a.order! > b.order!) ? 1 : -1)!;
    setCommunityActivities(sortedActivities);

    return saveOrdering(sortedActivities);
  }

  return (
    <>
      <Card className="rh-bg-teal border-0 mb-2">
        <CardBody className="pb-1">
          <p className="mb-2">Please list any community involvement.</p>
          <FormGroup check className="mr-2">
            <Label check>
              <Input
                type="checkbox"
                name="optOut"
                checked={optOut === true}
                onChange={toggleOptOut}
                disabled={formIsDisabled}
              />{" "}
              I have no community involvement.
            </Label>
          </FormGroup>
        </CardBody>
        {!optOut && communityActivities && (
          <CardBody>
            {communityActivities.map((a, index) => (
              <CommunityActivityForm
                key={a.key}
                communityActivity={a}
                onSubmit={saveCommunityActivity}
                onDelete={deleteCommunityActivity}
                formIsDisabled={formIsDisabled}
                onReorder={reorderCommunityActivity}
                upEnabled={a.order! > 0}
                downEnabled={a.order! < (communityActivities.length - 1)}
              />
            ))}
            <Button
              onClick={addBlankCommunityActivity}
              disabled={formIsDisabled}
            >
              <i className="fas fa-plus mr-2" /> Add Another
            </Button>
          </CardBody>
        )}
        <CardBody className="d-flex justify-content-between">
          <Button
            tag={Link}
            to={routes.toResumeEditAwards}
            color="link"
            className="btn-slim"
          >
            <i className="fas fa-chevron-left mr-2" /> Back
          </Button>
          <Button onClick={saveAndContinue} color="primary">
            Continue
          </Button>
        </CardBody>
      </Card>
      <Link to={routes.toResumeEditEmployment}>
        Skip and come back to later
      </Link>
    </>
  );
};

export default EditCommunityActivities;
