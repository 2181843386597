import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps, navigate, WindowLocation } from "@reach/router";
import {
  Container,
  Row,
  Col,
  Alert,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import agent from "../agent";
import { IRecRequest, IResume, IContentfulPage, IInviteClaim, IApiResult } from "../interfaces";
import Layout from "../components/Layout";
import ResumeViewDetails from "../components/resume/ViewDetails";
import RecRequestDetailedCard from "../components/RecRequestDetailedCard";
import Guide from "../components/Guide";
import FileDetails from "../components/FileDetails";
import { toDashboard } from "../routes";
import UserSettingsContext from "../context/user-settings-context";
import * as routes from "../routes";

interface IRecInvitesProps
  extends RouteComponentProps<{
    id: number;
  }> {
  pagesContent: IContentfulPage[];
  location?: WindowLocation;
}

const RecDetail: React.FC<IRecInvitesProps> = ({ id, pagesContent, location }) => {
  const [recRequest, setRecRequest] = useState<IRecRequest | null>(null);
  const { user } = useContext(UserSettingsContext);
  function getRecRequest() {
    if (!id) return;
    agent.RecRequests.getById(id)
      .then((res) => {
        setRecRequest(res.data);
      })
      .catch((rej) => {
        const message =
          rej.response.status === 404
            ? "Sorry, we couldn't find that Rec Request."
            : "Sorry, something went wrong. Please try again.";
        navigate(toDashboard, { state: { error: message } });
      });
  }
  useEffect(() => {
    if (!id) {
      navigate(`/`);
      return;
    }

    if (location?.search.includes("rrToken")) {
      const queryParams = new URLSearchParams(location?.search);
      const token = queryParams.get("rrToken");

      let claimToken = {
        invite_token: token,
        writer_id: user?.id ?? 0
      } as IInviteClaim;

      claimInvite(claimToken).then(res => {
        if (res && res.data) {
          getRecRequest();
        } else {
          navigate(routes.dashboardPath);
        }
      });


    } else {
      getRecRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function claimInvite(claim: IInviteClaim): Promise<IApiResult> {
    try {
        const claimRes = await agent.Invitations.claim(claim);
        return {
          message: "Invite claimed",
          data: claimRes.data,
        };
      } catch (e) {
        return {
          error: true,
          message: "Failed to claim invite",
        };
      }
    }

  function setStatus(status: string) {
    if (!id) return;
    agent.RecRequests.setStatus(id, status).then(getRecRequest);
  }
  useEffect(() => {    
    if (!id || !recRequest) return;
    if (recRequest && recRequest.status !== "unopened") return;
    setStatus("in_progress");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, recRequest]);

  const [activeTab, setActiveTab] = useState<string>("1");
  const activeClasses =
    "border-left-0 border-right-0 border-top-0 border-bottom-4 border-primary";
  const profile = recRequest && recRequest.pnm;
  const university = recRequest && recRequest.university;
  const resume: IResume = {
    profile,
    university,
    affiliations: (profile && profile.greek_affiliations) || [],
    activities: (profile && profile.activities) || [],
    awards: (profile && profile.awards) || [],
    communityActivities: (profile && profile.community_activities) || [],
    jobs: (profile && profile.jobs) || [],
  };
  const files = (recRequest && recRequest.files) || [];
  const recId = id || -1;
  return (
    <Layout>
      <Container className="rh-main-container">
        <Row>
          <Col>
            <RecRequestDetailedCard
              progress={(recRequest && recRequest.progress) || 0}
              photoUrl={(recRequest && recRequest.pnm.profile_photo_url) || ""}
              status={(recRequest && recRequest.status) || ""}
              name={(recRequest && recRequest.pnm.preferred_name) || ""}
              university={(recRequest && recRequest.university.name) || ""}
              mobile={(recRequest && recRequest.pnm.mobile_number) || ""}
              email={(recRequest && recRequest.pnm.pnm_email) || ""}
              sorority={(recRequest && recRequest.sorority.name) || ""}
            />
            <Row className="pt-2">
              <Col sm="0" md="3" />
              <Col md="6">
                <Alert color="info">
                  <strong>Rec Submission Deadline: </strong>
                  {recRequest && recRequest.deadline}
                </Alert>
              </Col>
            </Row>
            <Nav tabs fill className={activeTab === "3" ? "mb-2" : ""}>
              <NavItem>
                <NavLink
                  className={`${
                    activeTab === "1" ? activeClasses : "border-bottom-0"
                  }`}
                  onClick={() => setActiveTab("1")}
                >
                  Guide
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${
                    activeTab === "2" ? activeClasses : "border-bottom-0"
                  }`}
                  onClick={() => setActiveTab("2")}
                >
                  Resumé
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${
                    activeTab === "3" ? activeClasses : "border-bottom-0"
                  }`}
                  onClick={() => setActiveTab("3")}
                >
                  Files
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Guide
                  resume={resume}
                  progress={(recRequest && recRequest.progress) || 0}
                  status={(recRequest && recRequest.status) || ""}
                  setStatus={setStatus}
                  updateRecRequest={getRecRequest}
                  filesDownloaded={
                    (recRequest && recRequest.file_download_date != null) ||
                    false
                  }
                  files={files}
                  recId={recId}
                  pagesContent={pagesContent.filter(
                    (p) =>
                      p.title === "Rec Detail - Guide" ||
                      p.title === "Rec Detail - Rec Status" ||
                      p.title === "Rec Detail - Resume" ||
                      p.title === "Rec Detail - Files"
                  )}
                  goToResume={() => setActiveTab("2")}
                />
              </TabPane>
              <TabPane tabId="2">
                <ResumeViewDetails resume={resume} />
              </TabPane>
              <TabPane tabId="3">
                <FileDetails
                  files={files}
                  recId={recId}
                  updateRecRequest={getRecRequest}
                  resume={resume}
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default RecDetail;
